import {
  IonAvatar,
  IonCard,
  IonImg,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonSkeletonText,
  IonThumbnail,
} from "@ionic/react";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { IonItem, IonLabel } from "@ionic/react";
import appContext from "../../context/AppContext";
import constant from "../../constant";
import { useHistory } from "react-router-dom";
import { getAllNotification } from "../../service";
import moment from "moment";
import subscribenow from "../../assets/images/subs.png";
import PopUp from "./PopUp";
// import Details from "./Details";
import MultipleProfileUpload from "./MultipleProfileUpload";
import PushNotification from "./PushNotification";
import { useQuery } from "react-query";
const Details = React.lazy(() => import("./Details"));

const Notification: React.FC = () => {
  const AppContext = useContext(appContext);
  const [Notification, setNotification] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const [isOpenPopUp, setOpenPopUp] = useState<any>(false);
  const [isOpenDetail, setOpenDetail] = useState<any>(false);
  const [userID, setUserID] = useState<any>("");
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getNotificationList(pageNumber);
  }, []);

  const getNotificationList = async (page: any, event?: any) => {
    try {
      const response = await getAllNotification(page);
      if (response?.data?.status) {
        let uList = pageNumber === 0 ? [] : Notification;
        setNotification([...uList, ...response?.data?.data?.notification]);
        setTimeout(() => event.target.complete(), 500);
        setPageNumber(page + 1);
      }
    } catch (error) {
      console.error("Error fetching notification list:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const redirectUser = (user: any) => {
    if (user?.type === "new_visitor" || user?.type === "interest") {
      setUserID(user?.user?.id);
      setOpenDetail(true);
      // AppContext.setNotification(false);
    }
    if (user?.type === "popular") {
      if (AppContext?.user?.payment_verified_at) {
        history.push({
          pathname: "/app/profile/second-marrige",
          state: { type: "new_ten_visitor", name: "Visitor List" },
        });
        AppContext.setNotification(false);
      } else {
        setOpenPopUp(true);
      }
    }
    if (user?.type === "request_photo") {
      AppContext.setPopups(constant.popups.isOpenProfile, true);
      AppContext.setUpload({ key: "profile", name: "Profile" });
    }
  };

  const goTo = async () => {
    AppContext.setPopups(constant.popups.isSubscribe, true, true);
    setOpenPopUp(false);
  };

  return (
    <>
      {isLoading ? (
        <div>
          {[...Array(3)].map((user) => (
            <IonCard className="card ion-margin-top my-2">
              <IonItem onClick={() => {}}>
                <IonThumbnail className="ion-margin-end">
                  <IonSkeletonText animated></IonSkeletonText>
                </IonThumbnail>
                <IonLabel>
                  <h3>
                    <IonSkeletonText
                      animated={true}
                      style={{ width: "80%" }}
                    ></IonSkeletonText>
                  </h3>
                  <p>
                    <IonSkeletonText
                      animated={true}
                      style={{ width: "60%" }}
                    ></IonSkeletonText>
                  </p>
                </IonLabel>
              </IonItem>
            </IonCard>
          ))}
        </div>
      ) : (
        <>
          <div>
            <PushNotification />
            {Notification && Notification?.length < 1 ? (
              <IonCard className="card ">
                {" "}
                <p className="fs-16 ion-text-center ion-margin-top">
                  No New Notification !
                </p>
              </IonCard>
            ) : (
              <div>
                {Notification &&
                  Notification?.map((i: any) => {
                    return (
                      <IonCard className="card ion-margin-top my-2">
                        <IonItem
                          onClick={() => {
                            redirectUser(i);
                          }}
                        >
                          {i?.type !== "payment_done" ? (
                            i?.user ? (
                              i?.user?.profiles?.length === 0 ? (
                                <IonAvatar
                                  className="ion-margin-end"
                                  style={{ height: 55, width: 55 }}
                                >
                                  <img
                                    loading="lazy"
                                    src={
                                      "https://ionicframework.com/docs/img/demos/avatar.svg"
                                    }
                                  />
                                </IonAvatar>
                              ) : (
                                <IonAvatar
                                  className="ion-margin-end"
                                  style={{ height: 55, width: 55 }}
                                >
                                  <img
                                    loading="lazy"
                                    src={`${constant.ImageS3URl}/${i?.user?.profiles[0]?.folder}/${i?.user?.profiles[0]?.name}`}
                                  />
                                </IonAvatar>
                              )
                            ) : (
                              <IonAvatar
                                className="ion-margin-end"
                                style={{ height: 55, width: 55 }}
                              >
                                <img
                                  loading="lazy"
                                  src={
                                    "https://ionicframework.com/docs/img/demos/avatar.svg"
                                  }
                                />
                              </IonAvatar>
                            )
                          ) : (
                            <IonAvatar
                              className="ion-margin-end"
                              style={{ height: 55, width: 55 }}
                            >
                              <img
                                loading="lazy"
                                src={
                                  "https://ionicframework.com/docs/img/demos/avatar.svg"
                                }
                              />
                            </IonAvatar>
                          )}

                          <IonLabel className="notifictaionText">
                            <p>{i.title}</p>
                            <h3>
                              <b>{i.description}</b>
                            </h3>
                            <small className="fs-12">
                              <b>{moment(i.created_at).fromNow()}</b>
                            </small>
                          </IonLabel>
                        </IonItem>
                      </IonCard>
                    );
                  })}
                <IonInfiniteScroll
                  onIonInfinite={async (ev) => {
                    getNotificationList(pageNumber, ev);
                    // setTimeout(() => ev.target.complete(), 500);
                  }}
                >
                  <IonInfiniteScrollContent></IonInfiniteScrollContent>
                </IonInfiniteScroll>
              </div>
            )}
          </div>
          {/* <PushNotification /> */}
        </>
      )}
      {/* <MultipleProfileUpload /> */}
      <PopUp
        isOpenPopUp={isOpenPopUp}
        setOpenPopUp={setOpenPopUp}
        img={subscribenow}
        goTo={goTo}
        cancel={true}
        componentName={"subscribenow"}
      />
      <React.Suspense fallback={"Loading..."}>
        <Details
          isOpenDetail={isOpenDetail}
          dissmissOpenDetail={() => {
            setOpenDetail(false);
            setUserID(null);
          }}
          id={userID}
        />
      </React.Suspense>
    </>
  );
};
export default Notification;
export const MemoizedNotification = React.memo(Notification);
