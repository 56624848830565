import {
  IonAccordion,
  IonAccordionGroup,
  IonAlert,
  IonCard,
  IonContent,
  IonImg,
  IonList,
  IonModal,
  IonPopover,
  IonSkeletonText,
  IonSlides,
  IonSpinner,
  useIonAlert,
  useIonLoading,
  useIonRouter,
  useIonToast,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from "@ionic/react";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { IonItem, IonLabel } from "@ionic/react";
import { IonIcon, IonSlide } from "@ionic/react";
import { IonCol, IonRow } from "@ionic/react";
import { IonButton } from "@ionic/react";
import appContext from "../../context/AppContext";
import constant from "../../constant";
import {
  arrowBackOutline,
  callSharp,
  checkmarkCircleSharp,
  documentSharp,
  closeCircleOutline,
  documentText,
  heart,
  ellipsisVertical,
  locationOutline,
  lockClosedSharp,
  lockOpenSharp,
  logoWhatsapp,
  send,
  chevronForward,
  chevronBack,
  close,
} from "ionicons/icons";
import {
  addToFavoriteList,
  getProfileById,
  requestBiodata,
  sendInterestRequest,
  getCurrentPackage,
  unlock,
  home,
  deleteInterestRequest,
  blockedUser,
  shadiDoneUser,
  reportUser,
  birthPlaceRequestApi,
  motherMobileRequestApi,
  fatherMobileRequestApi,
  alternateMobileRequestApi,
  mobileRequestApi,
  incomeRequestApi,
} from "../../service";
import blank_user from "../../assets/images/blank_user.png";
import Packages from "./Packages";
import Lightbox from "react-image-lightbox";
import subscribe from "../../assets/images/subscribe.png";
import publish from "../../assets/images/publish.png";
import cancelsendinterest from "../../assets/images/cancelsendinterest.png";
import moment from "moment";
import { CallNumber } from "@awesome-cordova-plugins/call-number";
import PopUp from "./PopUp";
import GoLive from "./GoLive";
import report from "../../assets/images/report.png";
import weddingrings from "../../assets/images/weddingrings.png";
import blockuser from "../../assets/images/blockuser.png";
import "react-image-lightbox/style.css";
import { useStorage } from "../shared/useStorage";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router";
import { useQuery } from "react-query";
import useMediaQuery from "../../components/useMediaQuery";

function Details({ isOpenDetail, dissmissOpenDetail, id }: any) {
  const accordionGroup = useRef<null | HTMLIonAccordionGroupElement>(null);
  const AppContext = useContext(appContext);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>(null);
  const history = useHistory();
  const [open, setOpen] = useState<any>(false);
  const [openImage, setOpenImage] = useState<any>(false);
  const [isOpenPopUp, setOpenPopUp] = useState<any>(false);
  const [photoIndex, setPhotoIndex] = useState<any>(0);
  const [images, setImages] = useState<any>([]);
  const [showAlert, setShowAlert] = useState<any>(false);
  const [privateAlert, setPrivateAlert] = useState<any>(false);
  const [isBiodate, setIsBiodata] = useState<any>(false);
  const [showPopover, setShowPopover] = useState(false);
  const [presentLoading, dismiss] = useIonLoading();
  const [event, setEvent] = useState<any>();
  const [accordionOpen, setAccordionOpen] = useState<any>([]);
  const [present] = useIonToast();
  const [presentAlert] = useIonAlert();
  const [alertType, setAlertType] = useState<any>("");
  const isDesktop = useMediaQuery("(min-width: 768px)");
  const [sentRequest, setSendRequest] = useState<any>({
    mobile: false,
    fourth_formIncomeOfApplicantEnglish: false,
    second_formMobile: false,
    third_formBirthdetailsDescriptionEnglish: false,
    fifth_formFatherContactNumberMobile: false,
    fifth_formMotherContactNumberMobile: false,
  });
  const { userData, updateStorage } = useStorage(`userData-${id}`);
  const CurrentPackageBasic = AppContext?.currentPackages?.CurrentPackageBasic;
  const CurrentPackageVip = AppContext?.currentPackages?.CurrentPackageSpecial;
  const CurrentPackageSpecial = AppContext?.currentPackages?.CurrentPackageVip;
  const ionRouter = useIonRouter();
  document.addEventListener("ionBackButton", (ev: any) => {
    ev.detail.register(-1, () => {
      dismiss();
    });
  });

  const slideOpts = {
    initialSlide: 0,
    speed: 400,
    zoom: false,
  };

  const slideOpts2 = {
    initialSlide: 0,
    speed: 400,
    zoom: false,
  };

  const {
    data: profileData,
    isLoading,
    isError,
  } = useQuery(["profileData", id], () => getProfileById({ id }), {
    enabled: isOpenDetail,
    onSuccess: (data: any) => {
      const hasBiodata: any = data?.data?.data?.profiles.some(
        (profile: any) => profile.type === "birth"
      );
      setIsBiodata(hasBiodata);
    },
  });

  const handlePrivateAlert = () => {
    setPrivateAlert(!privateAlert);
  };

  const updateRequestStatus = (key: any) => {
    setSendRequest({ ...sentRequest, [key]: true });
  };

  const resetRequestButton = () => {
    setSendRequest({
      mobile: false,
      fourth_formIncomeOfApplicantEnglish: false,
      second_formMobile: false,
      third_formBirthdetailsDescriptionEnglish: false,
      fifth_formFatherContactNumberMobile: false,
      fifth_formMotherContactNumberMobile: false,
    });
  };

  useEffect(() => {
    setIsBiodata(false);
  }, [id]);

  useEffect(() => {
    if (isLoading && !profileData) {
      presentLoading({
        message: "Loading user data...",
        spinner: "circles",
      });
    } else {
      dismiss();
      setData(profileData?.data?.data || null);
    }
  }, [isLoading, profileData]);

  if (isError) return <p>Error fetching profile data</p>;

  const updateData = async (updatedData: any) => {
    setData(updatedData);
  };

  const addToFavorite = async (selectedUser: any) => {
    // setData({ ...data, favorite: !data?.favorite });
    const updatedData = { ...data, favorite: !data?.favorite };
    updateData(updatedData);
    await addToFavoriteList(selectedUser);
  };

  const sendInterest = async (selectedUser: any) => {
    var res: any = await sendInterestRequest(selectedUser);
    if (res?.data?.status) {
      // setData({ ...data, interest: !data?.interest });
      const updatedData = { ...data, interest: !data?.interest };
      updateData(updatedData);
      present({
        message: "The interest has been sent successfully.",
        duration: 1500,
        position: "top",
        color: "success",
        cssClass: "alertToast",
      });
    } else {
      presentAlert({
        header: res?.data?.message,
        cssClass: "actionAlert",
        buttons: [
          {
            text: "Ok",
            role: "confirm",
          },
        ],
      });
    }
  };

  const calculateAge = (dob: any) => {
    const now = moment();
    const birthDate = moment(dob);
    const age = now.diff(birthDate, "years");
    const nextBirthday = birthDate.add(age, "years");
    const hasHadBirthdayThisYear = now.isSameOrAfter(nextBirthday);

    return hasHadBirthdayThisYear ? age : age - 1;
  };

  const goTo = async () => {
    AppContext.setPopups(constant.popups.isSubscribe, true, true);
    setOpenPopUp(false);
    localStorage.setItem("activeUser", id);
  };

  const onDidDismiss = async () => {
    console.log("onDidDismiss called");
    resetRequestButton();
    setData(null);
    setOpen(false);
    setOpenImage(false);
    setImages([]);
    localStorage.setItem("isModal", "false");
    dissmissOpenDetail();
  };

  const onWillDismiss = async () => {
    console.log("onWillDismiss called");
    resetRequestButton();
    setOpen(false);
    setData(null);
    setOpenImage(false);
    setImages([]);
    localStorage.setItem("isModal", "false");
    dissmissOpenDetail();
  };

  const deleteInterestRequestFn = async () => {
    const res = await deleteInterestRequest(data);
    if (res?.data?.status) {
      // setData({ ...data, interest: !data?.interest });
      const updatedData = { ...data, interest: !data?.interest };
      updateData(updatedData);
      present({
        message: "Sent Interest Cancelled",
        duration: 1500,
        position: "top",
        color: "success",
        cssClass: "alertToast",
      });
      setShowAlert(false);
    }
  };

  const handleClick = async (type: string, subType: string = "") => {
    switch (type) {
      case "details":
        if (!AppContext?.user?.payment_verified_at)
          AppContext.setNoDelayPopup(constant.popups.isSubscribe);
        else if (
          CurrentPackageBasic === null &&
          CurrentPackageVip === null &&
          CurrentPackageSpecial !== null
        )
          AppContext.setNoDelayPopup(constant.popups.isSubscribe);
        else if (!AppContext?.user?.verified_at) {
          presentAlert({
            header: "Please wait for admin to approve your profile.",
            cssClass: "actionAlert",
            buttons: [{ text: "OK", role: "confirm" }],
          });
        } else {
          if (data?.viewCount < data?.viewLimit) {
            if (data?.unlock === false) {
              presentAlert({
                header: "Are you sure want to unlock this user profile?",
                cssClass: "actionAlert",
                buttons: [
                  {
                    text: "Yes",
                    role: "confirm",
                    handler: async () => {
                      var response = await unlock({ id: data?.id });
                      if (response?.data?.status) {
                        present({
                          message: `You have unlock ${
                            data?.viewCount + 1
                          } out of ${data?.viewLimit} profile.`,
                          duration: 1500,
                          position: "top",
                          color: "success",
                          cssClass: "alertToast",
                        });
                        // getProfileByIDTemp();
                        data.unlock = true;
                        const updatedData = { ...data, unlock: true };
                        updateData(updatedData);
                        if (subType === "biodata") {
                          var arr: any = [];
                          data?.profiles?.map((profile: any) => {
                            if (profile?.type === "birth") {
                              arr.push(
                                `${constant.ImageS3URl}/${profile?.folder}/${profile?.name}`
                              );
                              setImages(arr);
                            }
                          });
                          setOpen(true);
                        } else if (subType === "call")
                          await CallNumber.callNumber(data?.mobile, true);
                        else if (subType === "whatsapp")
                          window.location.href =
                            "https://wa.me/91" + `${data?.mobile}`;
                      }
                    },
                  },
                  {
                    text: "No",
                    role: "cancel",
                    handler: () => {},
                  },
                ],
              });
            } else {
              if (subType === "biodata") {
                var arr: any = [];
                data?.profiles?.map((profile: any) => {
                  if (profile?.type === "birth") {
                    arr.push(
                      `${constant.ImageS3URl}/${profile?.folder}/${profile?.name}`
                    );
                    setImages(arr);
                  }
                });
                setOpen(true);
              } else if (subType === "call")
                await CallNumber.callNumber(data?.mobile, true);
              else if (subType === "whatsapp")
                window.location.href = "https://wa.me/91" + `${data?.mobile}`;
            }
          } else {
            if (data?.unlock) {
              if (subType === "biodata") {
                var arr: any = [];
                data?.profiles?.map((profile: any) => {
                  if (profile?.type === "birth") {
                    arr.push(
                      `${constant.ImageS3URl}/${profile?.folder}/${profile?.name}`
                    );
                    setImages(arr);
                  }
                });
                setOpen(true);
              } else if (subType === "call") {
                await CallNumber.callNumber(data?.mobile, true);
              } else if (subType === "whatsapp") {
                window.location.href = "https://wa.me/91" + `${data?.mobile}`;
              }
            } else {
              present({
                message: `Your Limit is over of ${data?.viewLimit} users`,
                duration: 1500,
                position: "top",
                color: "primary",
                cssClass: "alertToast",
              });
            }
          }
        }
        break;

      case "sendInterest":
        if (!AppContext?.user?.second_formMobile)
          AppContext.setNoDelayPopup(constant.popups.isGoLive);
        else if (
          CurrentPackageBasic === null &&
          CurrentPackageVip === null &&
          CurrentPackageSpecial !== null
        )
          AppContext.setNoDelayPopup(constant.popups.isSubscribe);
        else if (!AppContext?.user?.payment_verified_at)
          AppContext.setNoDelayPopup(constant.popups.isSubscribe);
        // else if (!AppContext?.user?.live) {
        //     presentAlert({
        //         header: 'Please wait for admin to publish your profile.',
        //         cssClass: 'actionAlert',
        //         buttons: [{ text: 'OK', role: 'confirm' }],
        //     })
        // }
        else if (!data?.interest) sendInterest(data);
        else if (data?.interest) {
          setAlertType("cancel_request");
          setShowAlert(true);
        }
        break;
    }
  };
  const getBiodataRequest = async () => {
    let res = await requestBiodata({ id: id });
  };

  const handleBlockUser = async () => {
    let obj = {
      user_id: id,
      my_id: AppContext?.user?.id,
    };
    const res = await blockedUser(obj);
    if (res.data.status) {
      data.blockedUserIdByMe = true;
      const updatedData = { ...data, blockedUserIdByMe: true };
      updateData(updatedData);
      handlePopoverClose();
      present({
        message: "User Blocked",
        duration: 1500,
        position: "top",
        color: "success",
        cssClass: "alertToast",
      });
    }
  };
  const handleShadiDoneUser = async () => {
    let obj = {
      shadiDoneUserIds: [id],
    };
    const res = await shadiDoneUser(obj);
    if (res.data.status) {
      data.shadiDoneByMe = true;
      const updatedData = { ...data, shadiDoneByMe: true };
      updateData(updatedData);
      handlePopoverClose();
      present({
        message: "User marked as Shadi Done",
        duration: 1500,
        position: "top",
        color: "success",
        cssClass: "alertToast",
      });
    }
  };
  const handleReportUser = async () => {
    let obj = {
      reportUserUserIds: [id],
    };
    const res = await reportUser(obj);
    if (res.data.status) {
      handlePopoverClose();
      present({
        message: "User Reported",
        duration: 1500,
        position: "top",
        color: "success",
        cssClass: "alertToast",
      });
    }
  };

  const handleIconClick = (e: any) => {
    setShowPopover(true);
    setEvent(e);
  };

  const handlePopoverClose = () => {
    setShowPopover(false);
  };

  const LockUI = () => {
    return (
      <>
        {data?.unlock ? null : (
          <div
            onClick={(event) => {
              handleClick("details");
            }}
          >
            <IonIcon
              className="fs-22 "
              color="primary"
              icon={lockClosedSharp}
            />
          </div>
        )}
      </>
    );
  };

  const handleAlert = () => {
    if (alertType === "cancel_request") {
      deleteInterestRequestFn();
    } else if (alertType === "shadi_done") {
      handleShadiDoneUser();
    } else if (alertType === "report_user") {
      handleReportUser();
    } else if (alertType === "block_user") {
      handleBlockUser();
    }
  };

  const sendRequest = async (requestApi: any, id: any) => {
    const res = await requestApi({ id: id });
    if (res?.data?.status) {
      present({
        message: `Request sent Successfully`,
        duration: 1500,
        position: "top",
        color: "success",
        cssClass: "alertToast",
      });
    }
  };

  const requestHandler = (type: any, id: any) => {
    switch (type) {
      case "birthPlace":
        sendRequest(birthPlaceRequestApi, id);
        break;
      case "motherMobile":
        sendRequest(motherMobileRequestApi, id);
        break;
      case "fatherMobile":
        sendRequest(fatherMobileRequestApi, id);
        break;
      case "alternativeMobile":
        sendRequest(alternateMobileRequestApi, id);
        break;
      case "mobile":
        sendRequest(mobileRequestApi, id);
        break;
      case "income":
        sendRequest(incomeRequestApi, id);
        break;
      default:
        console.error(`Unsupported request type: ${type}`);
    }
  };

  const profileVisiblity = () => {
    let allow = false;
    switch (data?.profile_visibility) {
      case "show_to_all":
        return true;
      case "show_to_unmarried":
        allow =
          AppContext?.user?.first_formMaritalStatusEnglish === "Unmarried" &&
          data?.accept;
          if(data?.accept){
            return true
          }
          return allow;
      case "show_to_same_sect":
        allow =
          AppContext?.user?.third_formSectEnglish ===
            data?.third_formSectEnglish && data?.accept;
            if(data?.accept){
              return true
            }
        return allow;
      case "show_to_same_state":
        allow =
          AppContext?.user?.second_formStateEnglish ===
            data?.second_formStateEnglish && data?.accept;
            if(data?.accept){
              return true
            }
        return allow;
      default:
        return false;
    }
  };

  return (
    <>
      <Helmet>
        <meta property="og:title" content={data?.nameEnglish} />
        <meta
          name="description"
          content={
            data?._age !== null
              ? `${data?._age} Years`
              : `${moment().diff(data?.dob, "years")} Years`
          }
        />
        <meta
          property="og:image"
          content={`${constant.ImageS3URl}/${data?.profiles[0]?.folder}/${data?.profiles[0]?.name}`}
        />
      </Helmet>
      <IonModal
        isOpen={isOpenDetail}
        className="detailPageModal"
        onDidDismiss={onDidDismiss}
        onWillDismiss={onWillDismiss}
        onWillPresent={() => {
          localStorage.setItem("isModal", "true");
        }}
        onDidPresent={() => {
          localStorage.setItem("isModal", "true");
          setAccordionOpen(["first", "second", "third", "four", "five", "six"]);
        }}
        onIonModalWillPresent={() => {
          setAccordionOpen(["first", "second", "third", "four", "five", "six"]);
        }}
      >
        <IonContent>
          <div className="detailBody">
            <div>
              {data === null && (
                <IonSlides
                  pager={true}
                  options={slideOpts2}
                  className="detailSlidesMain"
                >
                  {[...Array(3)].map((i) => {
                    return (
                      <IonSlide key={i}>
                        <img
                          loading="lazy"
                          src={blank_user}
                          style={{
                            minHeight: 350,
                            maxHeight: 350,
                            objectFit: "cover",
                            width: "100%",
                          }}
                        ></img>
                        <div className="backBtn">
                          <IonButton
                            style={{
                              position: "absolute",
                              top: 10,
                              left: 10,
                            }}
                            className="px-1"
                            onClick={(event) => {
                              event.stopPropagation();
                              onDidDismiss();
                              if (
                                history?.location?.pathname ===
                                `/app/users/${data?.id}`
                              ) {
                                history.push("/app/home");
                              }
                            }}
                          >
                            <IonIcon icon={arrowBackOutline} color="white" />
                          </IonButton>
                        </div>
                        {AppContext?.user?.id !== id && (
                          <IonLabel
                            style={{
                              position: "absolute",
                              top: 15,
                              right: 10,
                            }}
                            className="heart-icon2"
                          ></IonLabel>
                        )}
                      </IonSlide>
                    );
                  })}
                </IonSlides>
              )}
            </div>
            <div>
              {data !== null && (
                <IonSlides options={slideOpts} className="detailSlidesMain">
                  {(data?.profiles?.length === 0 ||
                    (data?.profiles?.length === 1 &&
                      data?.profiles[0]?.type !== "profile")) && (
                    <div className="detailSlides">
                      <img
                        loading="lazy"
                        src={blank_user}
                        style={{
                          minHeight: 350,
                          height: 600,
                          objectFit: "cover",
                          width: "100%",
                        }}
                      ></img>
                      <div className="backBtn">
                        <IonButton
                          style={{ position: "absolute", top: 10, left: 10 }}
                          className="px-1"
                          onClick={(event) => {
                            console.log("Back button clicked not null data");
                            event.stopPropagation();
                            dissmissOpenDetail();
                            if (
                              history?.location?.pathname ===
                              `/app/users/${data?.id}`
                            ) {
                              history.push("/app/home");
                            }
                          }}
                        >
                          <IonIcon icon={arrowBackOutline} color="white" />
                        </IonButton>
                      </div>
                      {data?.profile_visibility !== "show_to_all" && !data?.accept && (
                        <IonButton
                          color={"success"}
                          className="px-2 privateBtn"
                          onClick={(e) => {
                            e.stopPropagation(); 
                            handlePrivateAlert();
                          }}
                        >
                          Private
                        </IonButton>
                      )}

                      {AppContext?.user?.id !== id && (
                        <IonLabel
                          style={{ position: "absolute", top: 15, right: 10 }}
                          className="heart-icon2"
                          onClick={(event) => {
                            event.stopPropagation();
                            if (AppContext?.user?.id !== id)
                              addToFavorite(data);
                          }}
                        >
                          <IonIcon
                            icon={heart}
                            color={data?.favorite ? "primary" : "secondary"}
                          />
                        </IonLabel>
                      )}
                      <IonLabel
                        style={{
                          margin: 0,
                          position: "absolute",
                          bottom: 15,
                          left: 18,
                          fontWeight: "bolder",
                          color: "#fff",
                          textAlign: "left",
                        }}
                      >
                        <p
                          className="text-light font-weight-500 m-0"
                          style={{ fontSize: 11, lineHeight: "12px" }}
                        >
                          <b>JSM-{data?.id}</b>
                        </p>
                        <div
                          style={{
                            display: "flex",
                            fontSize: "17px",
                            lineHeight: "28px",
                            textTransform: "capitalize",
                          }}
                        >
                          <b className="ellispses">{data.nameEnglish} </b>
                          &nbsp;|&nbsp;
                          <b>
                            {" "}
                            {data?.dob
                              ? calculateAge(data?.dob)
                              : data._age}{" "}
                            Yrs
                          </b>
                        </div>
                        <p
                          className="text-light dataText mb-0"
                          style={{ fontSize: "14px" }}
                        >
                          {data?.third_formSectEnglish}{" "}
                          {data?.first_formMaritalStatusEnglish &&
                            data?.third_formSectEnglish && <span>| </span>}
                          {data?.first_formMaritalStatusEnglish}
                        </p>
                        <p
                          className="text-light dataText mb-0"
                          style={{ fontSize: "12px" }}
                        >
                          <IonIcon
                            icon={locationOutline}
                            color="light"
                            style={{ verticalAlign: "sub" }}
                            className="me-1"
                          />
                          {data?.second_formCityEnglish}{" "}
                          {data?.second_formStateEnglish &&
                            data?.second_formStateEnglish && <span>| </span>}
                          {data?.second_formStateEnglish}
                        </p>
                      </IonLabel>
                    </div>
                  )}
                  {data?.profiles?.length === 1 &&
                    data?.profiles[0]?.type === "profile" && (
                      <div
                        className="detailSlides"
                        onClick={() => {
                          var arr: any = [];
                          data?.profiles?.map((profile: any) => {
                            if (profile?.type === "profile") {
                              arr.push(
                                `${constant.ImageS3URl}/${profile?.folder}/${profile?.name}`
                              );
                              setImages(arr);
                            }
                          });
                          setOpenImage(true);
                        }}
                      >
                        <img
                          loading="lazy"
                          src={`${constant.ImageS3URl}/${data?.profiles[0]?.folder}/${data?.profiles[0]?.name}`}
                          style={{
                            minHeight: 350,
                            height: 600,
                            objectFit: "cover",
                            width: "100%",
                          }}
                        />
                        <div className="backBtn">
                          <IonButton
                            style={{
                              position: "absolute",
                              top: 10,
                              left: 10,
                            }}
                            className="px-1"
                            onClick={(event) => {
                              console.log("Back button clicked not null data");
                              event.stopPropagation();
                              onDidDismiss();
                            }}
                          >
                            <IonIcon icon={arrowBackOutline} color="white" />
                          </IonButton>
                        </div>
                        {data?.profile_visibility !== "show_to_all" && !data?.accept && (
                          <IonButton
                            color={"success"}
                            className="px-2 privateBtn"
                            onClick={(e) => {
                              e.stopPropagation(); // Prevents the click from propagating to the image
                              handlePrivateAlert();
                            }}
                          >
                            Private
                          </IonButton>
                        )}
                        {AppContext?.user?.id !== id && (
                          <IonLabel
                            style={{
                              position: "absolute",
                              top: 15,
                              right: 10,
                            }}
                            className="heart-icon2"
                            onClick={(event) => {
                              event.stopPropagation();
                              if (AppContext?.user?.id !== id)
                                addToFavorite(data);
                            }}
                          >
                            <IonIcon
                              icon={heart}
                              color={data?.favorite ? "primary" : "secondary"}
                            />
                          </IonLabel>
                        )}

                        <IonLabel
                          style={{ position: "absolute", top: 53, right: 10 }}
                          className="heart-icon2"
                          onClick={(e) => {
                            e.stopPropagation();
                            if (isDesktop) {
                              presentAlert({
                                header: "Options",
                                // subHeader: "Option",
                                cssClass: "actionAlert",
                                buttons: [
                                  {
                                    text: "Block User",
                                    role: "confirm",
                                    handler: async () => {
                                      setAlertType("block_user");
                                      setShowAlert(true);
                                    },
                                  },
                                  {
                                    text: "Shadi Done",
                                    role: "confirm",
                                    handler: async () => {
                                      if (
                                        !AppContext?.user?.payment_verified_at
                                      ) {
                                        AppContext.setNoDelayPopup(
                                          constant.popups.isSubscribe
                                        );
                                      } else {
                                        setAlertType("shadi_done");
                                        setShowAlert(true);
                                      }
                                    },
                                  },
                                  {
                                    text: "Report User",
                                    role: "confirm",
                                    handler: async () => {
                                      if (
                                        !AppContext?.user?.payment_verified_at
                                      ) {
                                        AppContext.setNoDelayPopup(
                                          constant.popups.isSubscribe
                                        );
                                      } else {
                                        setAlertType("report_user");
                                        setShowAlert(true);
                                      }
                                    },
                                  },
                                  {
                                    text: "Cancel",
                                    role: "confirm",
                                    handler: () => {},
                                  },
                                ],
                              });
                            } else {
                              handleIconClick(e);
                            }
                          }}
                        >
                          <IonIcon icon={ellipsisVertical} color="dark" />
                        </IonLabel>

                        <IonLabel
                          style={{
                            margin: 0,
                            position: "absolute",
                            bottom: 15,
                            left: 18,
                            fontWeight: "bolder",
                            color: "#fff",
                            textAlign: "left",
                          }}
                        >
                          <p
                            className="text-light font-weight-500 m-0"
                            style={{ fontSize: 11, lineHeight: "12px" }}
                          >
                            <b>JSM-{data?.id}</b>
                          </p>
                          <div
                            style={{
                              display: "flex",
                              fontSize: "17px",
                              lineHeight: "28px",
                              textTransform: "capitalize",
                            }}
                          >
                            <b className="ellispses">{data.nameEnglish} </b>
                            &nbsp;|&nbsp;
                            <b>
                              {" "}
                              {data?.dob
                                ? calculateAge(data?.dob)
                                : data._age}{" "}
                              Yrs
                            </b>
                          </div>
                          <p
                            className="text-light dataText mb-0"
                            style={{ fontSize: "14px" }}
                          >
                            {data?.third_formSectEnglish}{" "}
                            {data?.first_formMaritalStatusEnglish &&
                              data?.third_formSectEnglish && <span>| </span>}
                            {data?.first_formMaritalStatusEnglish}
                          </p>
                          <p
                            className="text-light dataText mb-0"
                            style={{ fontSize: "12px" }}
                          >
                            <IonIcon
                              icon={locationOutline}
                              color="light"
                              style={{ verticalAlign: "sub" }}
                              className="me-1"
                            />
                            {data?.second_formCityEnglish}{" "}
                            {data?.second_formStateEnglish &&
                              data?.second_formStateEnglish && <span>| </span>}
                            {data?.second_formStateEnglish}
                          </p>
                        </IonLabel>
                      </div>
                    )}
                  {data?.profiles?.length > 1 &&
                    data?.profiles?.map((profile: any, index: any) => {
                      return (
                        profile?.type === "profile" && (
                          <IonSlide
                            key={index}
                            className="detailSlides"
                            onClick={() => {
                              var arr: any = [];
                              data?.profiles?.map((profile: any) => {
                                if (profile?.type === "profile") {
                                  arr.push(
                                    `${constant.ImageS3URl}/${profile?.folder}/${profile?.name}`
                                  );
                                  setImages(arr);
                                }
                              });
                              setOpenImage(true);
                            }}
                          >
                            <img
                              loading="lazy"
                              src={`${constant.ImageS3URl}/${profile.folder}/${profile.name}`}
                              style={{
                                minHeight: 350,
                                height: 600,
                                objectFit: "cover",
                                objectPosition: "top",
                                width: "100%",
                              }}
                            />
                            <div className="backBtn">
                              <IonButton
                                style={{
                                  position: "absolute",
                                  top: 10,
                                  left: 10,
                                }}
                                className="px-1"
                                onClick={(event) => {
                                  console.log(
                                    "Back button clicked not null data"
                                  );
                                  event.stopPropagation();
                                  dissmissOpenDetail();
                                }}
                              >
                                <IonIcon
                                  icon={arrowBackOutline}
                                  color="white"
                                />
                              </IonButton>
                            </div>
                            {data?.profile_visibility !== "show_to_all" && !data?.accept && (
                              <IonButton
                                color={"success"}
                                className="px-2 privateBtn"
                                onClick={(e) => {
                                  e.stopPropagation(); 
                                  handlePrivateAlert();
                                }}
                              >
                                Private
                              </IonButton>
                            )}
                            {AppContext?.user?.id !== id && (
                              <IonLabel
                                style={{
                                  position: "absolute",
                                  top: 15,
                                  right: 10,
                                }}
                                className="heart-icon2"
                                onClick={(event) => {
                                  event.stopPropagation();
                                  if (AppContext?.user?.id !== id)
                                    addToFavorite(data);
                                }}
                              >
                                <IonIcon
                                  icon={heart}
                                  color={
                                    data?.favorite ? "primary" : "secondary"
                                  }
                                />
                              </IonLabel>
                            )}
                            <IonLabel
                              style={{
                                position: "absolute",
                                top: 53,
                                right: 10,
                              }}
                              className="heart-icon2"
                              onClick={(e) => {
                                e.stopPropagation();
                                if (isDesktop) {
                                  presentAlert({
                                    header: "Options",
                                    // subHeader: "Option",
                                    cssClass: "actionAlertOption",
                                    buttons: [
                                      {
                                        text: "Block User",
                                        role: "confirm",
                                        handler: async () => {
                                          setAlertType("block_user");
                                          setShowAlert(true);
                                        },
                                      },
                                      {
                                        text: "Shadi Done",
                                        role: "confirm",
                                        handler: async () => {
                                          if (
                                            !AppContext?.user
                                              ?.payment_verified_at
                                          ) {
                                            AppContext.setNoDelayPopup(
                                              constant.popups.isSubscribe
                                            );
                                          } else {
                                            setAlertType("shadi_done");
                                            setShowAlert(true);
                                          }
                                        },
                                      },
                                      {
                                        text: "Report User",
                                        role: "confirm",
                                        handler: async () => {
                                          if (
                                            !AppContext?.user
                                              ?.payment_verified_at
                                          ) {
                                            AppContext.setNoDelayPopup(
                                              constant.popups.isSubscribe
                                            );
                                          } else {
                                            setAlertType("report_user");
                                            setShowAlert(true);
                                          }
                                        },
                                      },
                                      {
                                        text: "Cancel",
                                        role: "confirm",
                                        handler: () => {},
                                      },
                                    ],
                                  });
                                } else {
                                  handleIconClick(e);
                                }
                              }}
                            >
                              <IonIcon icon={ellipsisVertical} color="dark" />
                            </IonLabel>

                            <IonLabel
                              style={{
                                margin: 0,
                                position: "absolute",
                                bottom: 13,
                                left: 18,
                                fontWeight: "bolder",
                                color: "#fff",
                                textAlign: "left",
                              }}
                            >
                              <p
                                className="text-light font-weight-500 m-0"
                                style={{ fontSize: 11, lineHeight: "12px" }}
                              >
                                <b>JSM-{data?.id}</b>
                              </p>
                              <div
                                style={{
                                  display: "flex",
                                  fontSize: "17px",
                                  lineHeight: "24px",
                                  textTransform: "capitalize",
                                }}
                              >
                                <b className="ellispses">{data.nameEnglish} </b>
                                &nbsp;|&nbsp;
                                <b>
                                  {" "}
                                  {data?.dob
                                    ? calculateAge(data?.dob)
                                    : data._age}{" "}
                                  Yrs
                                </b>
                              </div>
                              <p
                                className="text-light dataText mb-0"
                                style={{ fontSize: "12px" }}
                              >
                                {data?.third_formSectEnglish}{" "}
                                {data?.first_formMaritalStatusEnglish &&
                                  data?.third_formSectEnglish && (
                                    <span>| </span>
                                  )}
                                {data?.first_formMaritalStatusEnglish}
                              </p>
                              <p
                                className="text-light dataText mb-0"
                                style={{ fontSize: "12px" }}
                              >
                                <IonIcon
                                  icon={locationOutline}
                                  color="light"
                                  style={{ verticalAlign: "sub" }}
                                  className="me-1"
                                />
                                {data?.second_formCityEnglish}{" "}
                                {data?.second_formStateEnglish &&
                                  data?.second_formStateEnglish && (
                                    <span>| </span>
                                  )}
                                {data?.second_formStateEnglish}
                              </p>
                            </IonLabel>
                          </IonSlide>
                        )
                      );
                    })}
                  {openImage && (
                    <Lightbox
                      wrapperClassName="ril_outer"
                      clickOutsideToClose={false}
                      imageTitle="Profiles"
                      imageLoadErrorMessage="This image failed to load"
                      onImageLoad={() => {
                        window.dispatchEvent(new Event("resize"));
                      }}
                      mainSrc={images[photoIndex]}
                      nextSrc={images[(photoIndex + 1) % images.length]}
                      prevSrc={
                        images[(photoIndex + images.length - 1) % images.length]
                      }
                      onCloseRequest={() => setOpenImage(false)}
                      onMovePrevRequest={() =>
                        setPhotoIndex(
                          (photoIndex + images.length - 1) % images.length
                        )
                      }
                      onMoveNextRequest={() =>
                        setPhotoIndex((photoIndex + 1) % images.length)
                      }
                    />
                  )}
                </IonSlides>
              )}
            </div>

            <IonCard className="bg-none mb-0 mt-1">
              {AppContext?.user?.id !== id && (
                <IonRow
                  style={{ alignItems: "center" }}
                  className="detailBtnGrp mt-2"
                >
                  {profileVisiblity() && (
                    <>
                      <IonCol size="3">
                        <div className="ion-text-center">
                          <div
                            className="btnICon"
                            style={{ background: "#03852c66" }}
                            onClick={() => {
                              if (!data) return;
                              handleClick("details", "call");
                            }}
                          >
                            <IonIcon
                              slot="icon-only"
                              icon={callSharp}
                              style={{
                                color: "#03852c",
                                verticalAlign: "middle",
                              }}
                            ></IonIcon>
                          </div>
                          <a href={`tel:+91${data?.mobile}`}>
                            <p style={{ color: "#03852c" }}>
                              Call <br /> Now
                            </p>
                          </a>
                        </div>
                      </IonCol>
                      <IonCol size="3">
                        <div className="ion-text-center">
                          <div
                            className="btnICon"
                            style={{ background: "#03852c66" }}
                            onClick={() => {
                              if (!data) return;
                              handleClick("details", "whatsapp");
                            }}
                          >
                            <IonIcon
                              slot="icon-only"
                              icon={logoWhatsapp}
                              style={{
                                color: "#03852c",
                                verticalAlign: "middle",
                              }}
                            ></IonIcon>
                          </div>
                          <p style={{ color: "#03852c" }}>
                            WhatsApp <br /> Message
                          </p>
                        </div>
                      </IonCol>
                      <IonCol size="3">
                        {isBiodate ? (
                          <div className="ion-text-center">
                            <div
                              className="btnICon"
                              style={{ background: "#3655e452 " }}
                              onClick={(event) => {
                                if (!data) return;
                                handleClick("details", "biodata");
                              }}
                            >
                              <IonIcon
                                slot="icon-only"
                                icon={documentSharp}
                                style={{
                                  color: "#3655e4",
                                  verticalAlign: "middle",
                                }}
                              ></IonIcon>
                            </div>
                            <p style={{ color: "#3655e4" }}>
                              View <br /> Biodata
                            </p>
                          </div>
                        ) : (
                          <div className="ion-text-center">
                            <div
                              className="btnICon"
                              style={{ background: "#3655e452 " }}
                              onClick={(event) => {
                                if (!data) return;
                                getBiodataRequest();
                              }}
                            >
                              <IonIcon
                                slot="icon-only"
                                icon={documentText}
                                style={{
                                  color: "#3655e4",
                                  verticalAlign: "middle",
                                }}
                              ></IonIcon>
                            </div>
                            <p style={{ color: "#3655e4" }}>
                              Request <br /> Biodata
                            </p>
                          </div>
                        )}
                      </IonCol>
                    </>
                  )}
                  <IonCol
                    size={profileVisiblity() ? "3" : ""}
                    className={
                      profileVisiblity() ? "" : "d-flex justify-content-center"
                    }
                  >
                    <div className="ion-text-center">
                      <div
                        className="btnICon"
                        style={{ background: "#e405014f" }}
                        onClick={() => {
                          if (!data) return;
                          handleClick("sendInterest");
                        }}
                      >
                        {!data?.interest ? (
                          <IonIcon
                            slot="icon-only"
                            className="white m-0"
                            icon={send}
                            style={{
                              color: "#e40501 ",
                              verticalAlign: "middle",
                            }}
                          ></IonIcon>
                        ) : (
                          <IonIcon
                            slot="icon-only"
                            className="white m-0"
                            icon={close}
                            style={{
                              color: "#e40501 ",
                              verticalAlign: "middle",
                              transform: "rotate(90deg)",
                            }}
                          ></IonIcon>
                        )}
                      </div>
                      {!data?.interest ? (
                        <p style={{ color: "#e40501" }}>
                          Send <br /> Interest
                        </p>
                      ) : (
                        <p style={{ color: "#e40501" }}>
                          Cancel <br /> Interest
                        </p>
                      )}
                    </div>
                  </IonCol>
                </IonRow>
              )}
            </IonCard>
            {!profileVisiblity() && (
              <IonCard className="bg-none mb-0 mt-1">
                <IonRow style={{ position: "relative" }}>
                  <IonCol></IonCol>
                  <IonCol></IonCol>
                  <IonCol></IonCol>
                </IonRow>
              </IonCard>
            )}

            {data?.unlock ? null : (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <IonButton
                  disabled={!data}
                  onClick={(event) => {
                    handleClick("details");
                  }}
                  size="large"
                  style={{ padding: "5px", width: "88%" }}
                >
                  <IonIcon
                    style={{ margin: "10px" }}
                    color="white"
                    icon={lockOpenSharp}
                  />
                  <h3 style={{ margin: "0px 21px 0px 12px" }}>
                    <b>Unlock This Profile</b>
                  </h3>
                </IonButton>
              </div>
            )}

            {/* surbhi jain test */}
            <IonAccordionGroup
              expand="inset"
              ref={accordionGroup}
              value={accordionOpen}
              multiple={true}
            >
              <IonAccordion value="first">
                <IonItem slot="header">
                  <IonLabel className="accordion-expanding">
                    Basic Details
                  </IonLabel>
                </IonItem>
                <div className="ion-padding datas" slot="content">
                  <IonRow className="ion-align-items-center">
                    <IonCol>
                      <h6 className="headText mb-0">Created For</h6>
                      <p className="dataText mb-0">
                        {data?.first_formProfileEnglish || "-"}
                      </p>
                    </IonCol>
                    <IonCol>
                      <h6 className="headText mb-0">Height</h6>
                      <p className="dataText mb-0">
                        {data?.second_formHeightFeet ||
                          "-" +
                            " Ft. (" +
                            (data?.second_formHeightCm || "-") +
                            " CM)"}
                      </p>
                    </IonCol>
                  </IonRow>
                  <IonRow className="ion-align-items-center">
                    <IonCol>
                      <h6 className="headText mb-0">Physical Status</h6>
                      <p className="dataText mb-0">
                        {data?.second_formPhysicalStatusEnglish || "-"}
                      </p>
                    </IonCol>
                    <IonCol>
                      <h6 className="headText mb-0">Mother tongue</h6>
                      <p className="dataText mb-0">
                        {data?.second_formMotherTongueEnglish || "-"}
                      </p>
                    </IonCol>
                  </IonRow>
                  <IonRow className="ion-align-items-center">
                    <IonCol>
                      <h6 className="headText mb-0">Eating habits</h6>
                      <p className="dataText mb-0">
                        {data?.second_formEatingHabitsEnglish || "-"}
                      </p>
                    </IonCol>
                  </IonRow>
                  {data?.first_formMaritalStatusEnglish === "Divorced" ||
                  data?.first_formMaritalStatusEnglish === "Widow/Widower" ? (
                    <IonRow className="ion-align-items-center">
                      <IonCol>
                        <h6 className="headText mb-0">No. of Child</h6>
                        <p className="dataText mb-0">
                          {data?.first_formNoOfChildren || "-"}
                        </p>
                      </IonCol>
                    </IonRow>
                  ) : (
                    <></>
                  )}
                </div>
              </IonAccordion>
              <IonAccordion value="second">
                <IonItem slot="header">
                  <IonLabel className="accordion-expanding">
                    Professional Information
                  </IonLabel>
                </IonItem>
                <div
                  className="ion-padding datas"
                  slot="content"
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                >
                  <IonRow className="ion-align-items-center p-0">
                    <IonCol
                      size="12"
                      className="displayPropAround my-1 ion-align-items-start"
                    >
                      <div>
                        <IonIcon
                          className="fs-22 me-3"
                          color="primary"
                          icon={checkmarkCircleSharp}
                        />
                      </div>
                      <div>
                        <h6 className="headText mb-0">Education</h6>
                        <p className="dataText mb-0">
                          {data?.fourth_formEducationEnglish || "-"}
                        </p>
                      </div>
                    </IonCol>
                    <IonCol
                      size="12"
                      className="displayPropAround my-1 ion-align-items-start"
                    >
                      <div>
                        <IonIcon
                          className="fs-22 me-3"
                          color="primary"
                          icon={checkmarkCircleSharp}
                        />
                      </div>
                      <div>
                        <h6 className="headText mb-0">Describe Education</h6>
                        <p className="dataText mb-0">
                          {data?.fourth_formDescribeEducationEnglish || "-"}
                        </p>
                      </div>
                    </IonCol>
                    <IonCol
                      size="12"
                      className="displayPropAround my-1 ion-align-items-start"
                    >
                      <div>
                        <IonIcon
                          className="fs-22 me-3"
                          color="primary"
                          icon={checkmarkCircleSharp}
                        />
                      </div>
                      <div>
                        <h6 className="headText mb-0">Profession</h6>
                        <p className="dataText mb-0">
                          {data?.fourth_formEmployedEnglish || "-"}
                        </p>
                      </div>
                    </IonCol>
                    <IonCol size="12" className="displayProp my-1 ">
                      <div className="displayPropAround ion-align-items-start">
                        <div>
                          <IonIcon
                            className="fs-22 me-3"
                            color="primary"
                            icon={checkmarkCircleSharp}
                          />
                        </div>
                        <div>
                          <h6 className="headText mb-0">
                            Income of Applicant (Per Annum)
                          </h6>
                          <p className="dataText mb-0">
                            {/* {AppContext?.user?.id === id
                              ? data?.fourth_formIncomeOfApplicantEnglish || "-"
                              : AppContext?.user?.payment_verified_at &&
                                data?.unlock
                              ? data?.fourth_formIncomeOfApplicantEnglish || "-"
                              : "****** ****** ****"} */}
                            {AppContext?.user?.id === id ? (
                              data?.fourth_formIncomeOfApplicantEnglish || "-"
                            ) : AppContext?.user?.payment_verified_at &&
                              data?.unlock ? (
                              data?.fourth_formIncomeOfApplicantEnglish ? (
                                data?.fourth_formIncomeOfApplicantEnglish
                              ) : (
                                "*** Not Available ***"
                              )
                            ) : !data?.fourth_formIncomeOfApplicantEnglish ? (
                              <>*** Not Available ***</>
                            ) : (
                              "****** ****** ****"
                            )}
                          </p>
                        </div>
                      </div>
                      {!data?.fourth_formIncomeOfApplicantEnglish ? (
                        <>
                          <button
                            disabled={!data}
                            className="btn btn-danger btn-sm"
                            style={{
                              width: "5.7em",
                              height: "2.5em",
                              padding: "1px 2px",
                            }}
                            onClick={() => {
                              if (!AppContext?.user?.payment_verified_at) {
                                AppContext.setNoDelayPopup(
                                  constant.popups.isSubscribe
                                );
                              } else {
                                if (data?.id) {
                                  if (
                                    !sentRequest?.fourth_formIncomeOfApplicantEnglish
                                  ) {
                                    requestHandler("income", data?.id);
                                    updateRequestStatus(
                                      "fourth_formIncomeOfApplicantEnglish"
                                    );
                                  }
                                } else {
                                  present({
                                    message: `Loading user details...`,
                                    duration: 1500,
                                    position: "top",
                                    color: "success",
                                    cssClass: "alertToast",
                                  });
                                }
                              }
                            }}
                          >
                            {sentRequest?.fourth_formIncomeOfApplicantEnglish
                              ? "Sent"
                              : "Request"}
                          </button>
                        </>
                      ) : (
                        LockUI()
                      )}
                    </IonCol>
                    <IonCol
                      size="12"
                      className="displayPropAround my-1 ion-align-items-start"
                    >
                      <div>
                        <IonIcon
                          className="fs-22 me-3"
                          color="primary"
                          icon={checkmarkCircleSharp}
                        />
                      </div>
                      <div>
                        <h6 className="headText mb-0">Hobbies/Interest</h6>
                        <p className="dataText mb-0">
                          {data?.fourth_formHobbiesInterest?.map(
                            (i: any, ind: any) => i?.english + ", "
                          )}
                          {data?.fourth_formHobbiesInterestWriteEnglish || "-"}
                        </p>
                      </div>
                    </IonCol>
                  </IonRow>
                </div>
              </IonAccordion>
              <IonAccordion value="third">
                <IonItem slot="header">
                  <IonLabel className="accordion-expanding">
                    Contact Details
                  </IonLabel>
                </IonItem>
                <div
                  className="ion-padding datas"
                  slot="content"
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                >
                  <IonRow className="ion-align-items-center">
                    <IonCol size="12" className="displayProp my-1 ">
                      <div className="displayPropAround ion-align-items-start">
                        <div>
                          <IonIcon
                            className="fs-22 me-3"
                            color="primary"
                            icon={checkmarkCircleSharp}
                          />
                        </div>
                        <div>
                          <h6 className="headText mb-0">
                            Registered Mobile Number
                          </h6>
                          <p className="dataText mb-0">
                            {/* {AppContext?.user?.id === id
                              ? data?.mobile || "-"
                              : AppContext?.user?.payment_verified_at &&
                                data?.unlock
                              ? data?.mobile || "-"
                              : "****** ****** ****"} */}
                            {AppContext?.user?.id === id ? (
                              data?.mobile || "-"
                            ) : AppContext?.user?.payment_verified_at &&
                              data?.unlock &&
                              profileVisiblity() ? (
                              data?.mobile ? (
                                data?.mobile
                              ) : (
                                "*** Not Available ***"
                              )
                            ) : !data?.mobile ? (
                              <>*** Not Available ***</>
                            ) : (
                              "****** ****** ****"
                            )}
                          </p>
                        </div>
                      </div>
                      {!data?.mobile ? (
                        <>
                          <button
                            disabled={!data}
                            className="btn btn-danger btn-sm"
                            style={{
                              width: "5.7em",
                              height: "2.5em",
                              padding: "1px 2px",
                            }}
                            onClick={() => {
                              if (!AppContext?.user?.payment_verified_at) {
                                AppContext.setNoDelayPopup(
                                  constant.popups.isSubscribe
                                );
                              } else {
                                if (data?.id) {
                                  if (!sentRequest?.mobile) {
                                    requestHandler("mobile", data?.id);
                                    updateRequestStatus("mobile");
                                  }
                                } else {
                                  present({
                                    message: `Loading user details...`,
                                    duration: 1500,
                                    position: "top",
                                    color: "success",
                                    cssClass: "alertToast",
                                  });
                                }
                              }
                            }}
                          >
                            {sentRequest?.mobile ? "Sent" : "Request"}
                          </button>
                        </>
                      ) : (
                        LockUI()
                      )}
                    </IonCol>
                    <IonCol size="12" className="displayProp my-1 ">
                      <div className="displayPropAround ion-align-items-start">
                        <div>
                          <IonIcon
                            className="fs-22 me-3"
                            color="primary"
                            icon={checkmarkCircleSharp}
                          />
                        </div>
                        <div>
                          <h6 className="headText mb-0">
                            Alternate mobile numbers
                          </h6>
                          <p className="dataText mb-0">
                            {/* {AppContext?.user?.id === id
                              ? data?.second_formMobile || "-"
                              : AppContext?.user?.payment_verified_at &&
                                data?.unlock
                              ? data?.second_formMobile || "-"
                              : "****** ****** ****"} */}
                            {AppContext?.user?.id === id ? (
                              data?.second_formMobile || "-"
                            ) : AppContext?.user?.payment_verified_at &&
                              data?.unlock &&
                              profileVisiblity() ? (
                              data?.second_formMobile ? (
                                data?.second_formMobile
                              ) : (
                                "*** Not Available ***"
                              )
                            ) : !data?.second_formMobile ? (
                              <>*** Not Available ***</>
                            ) : (
                              "****** ****** ****"
                            )}
                          </p>
                        </div>
                      </div>
                      {!data?.second_formMobile ? (
                        <>
                          <button
                            disabled={!data}
                            className="btn btn-danger btn-sm"
                            style={{
                              width: "5.7em",
                              height: "2.5em",
                              padding: "1px 2px",
                            }}
                            onClick={() => {
                              if (!AppContext?.user?.payment_verified_at) {
                                AppContext.setNoDelayPopup(
                                  constant.popups.isSubscribe
                                );
                              } else {
                                if (data?.id) {
                                  if (!sentRequest?.second_formMobile) {
                                    requestHandler(
                                      "alternativeMobile",
                                      data?.id
                                    );
                                    updateRequestStatus("second_formMobile");
                                  }
                                } else {
                                  present({
                                    message: `Loading user details...`,
                                    duration: 1500,
                                    position: "top",
                                    color: "success",
                                    cssClass: "alertToast",
                                  });
                                }
                              }
                            }}
                          >
                            {sentRequest?.second_formMobile
                              ? "Sent"
                              : "Request"}
                          </button>
                        </>
                      ) : (
                        LockUI()
                      )}
                    </IonCol>
                    <IonCol size="12" className="displayProp my-1 ">
                      <div className="displayPropAround ion-align-items-start">
                        <div>
                          <IonIcon
                            className="fs-22 me-3"
                            color="primary"
                            icon={checkmarkCircleSharp}
                          />
                        </div>
                        <div>
                          <h6 className="headText mb-0">Home Town Address</h6>
                          <p className="dataText mb-0">
                            {AppContext?.user?.id === id ? (
                              data?.second_formAddressEnglish || "-"
                            ) : AppContext?.user?.payment_verified_at &&
                              data?.unlock ? (
                              data?.second_formAddressEnglish ? (
                                data?.second_formAddressEnglish
                              ) : (
                                "*** Not Available ***"
                              )
                            ) : !data?.second_formAddressEnglish ? (
                              <>*** Not Available ***</>
                            ) : (
                              "****** ****** ****"
                            )}
                          </p>
                        </div>
                      </div>
                      {!data?.unlock && LockUI()}
                    </IonCol>
                    <IonCol size="12" className="displayProp my-1 ">
                      <div className="displayPropAround ion-align-items-start">
                        <div>
                          <IonIcon
                            className="fs-22 me-3"
                            color="primary"
                            icon={checkmarkCircleSharp}
                          />
                        </div>
                        <div>
                          <h6 className="headText mb-0">
                            Candidate Current Address
                          </h6>
                          <p className="dataText mb-0">
                            {AppContext?.user?.id === id ? (
                              data?.second_formLivingAddressEnglish || "-"
                            ) : AppContext?.user?.payment_verified_at &&
                              data?.unlock ? (
                              data?.second_formLivingAddressEnglish ? (
                                data?.second_formLivingAddressEnglish
                              ) : (
                                "*** Not Available ***"
                              )
                            ) : !data?.second_formLivingAddressEnglish ? (
                              <>*** Not Available ***</>
                            ) : (
                              "****** ****** ****"
                            )}
                          </p>
                        </div>
                      </div>
                      {!data?.unlock && LockUI()}
                    </IonCol>
                  </IonRow>
                </div>
              </IonAccordion>
              <IonAccordion value="four">
                <IonItem slot="header">
                  <IonLabel className="accordion-expanding">
                    Religious Information
                  </IonLabel>
                </IonItem>
                <div
                  className="ion-padding datas"
                  slot="content"
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                >
                  <IonRow className="ion-align-items-center">
                    <IonCol
                      size="12"
                      className="displayPropAround my-1 ion-align-items-start"
                    >
                      <div>
                        <IonIcon
                          className="fs-22 me-3"
                          color="primary"
                          icon={checkmarkCircleSharp}
                        />
                      </div>
                      <div>
                        <h6 className="headText mb-0">Self Sect</h6>
                        <p className="dataText mb-0">
                          {data?.third_formSectEnglish || "-"}
                        </p>
                      </div>
                    </IonCol>
                    <IonCol
                      size="12"
                      className="displayPropAround my-1 ion-align-items-start"
                    >
                      <div>
                        <IonIcon
                          className="fs-22 me-3"
                          color="primary"
                          icon={checkmarkCircleSharp}
                        />
                      </div>
                      <div>
                        <h6 className="headText mb-0"> Sub Sect</h6>
                        <p className="dataText mb-0">
                          {data?.third_formSubSectEnglish || "-"}
                        </p>
                      </div>
                    </IonCol>
                    <IonCol
                      size="12"
                      className="displayPropAround my-1 ion-align-items-start"
                    >
                      <div>
                        <IonIcon
                          className="fs-22 me-3"
                          color="primary"
                          icon={checkmarkCircleSharp}
                        />
                      </div>
                      <div>
                        <h6 className="headText mb-0"> Gotra</h6>
                        <p className="dataText mb-0">
                          {data?.third_formGotraEnglish || "-"}
                        </p>
                      </div>
                    </IonCol>
                  </IonRow>
                </div>
              </IonAccordion>
              <IonAccordion value="five">
                <IonItem slot="header">
                  <IonLabel className="accordion-expanding">
                    Horoscope Details
                  </IonLabel>
                </IonItem>
                <div
                  className="ion-padding datas"
                  slot="content"
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                >
                  <IonRow className="ion-align-items-center">
                    <IonCol
                      size="12"
                      className="displayPropAround my-1 ion-align-items-start"
                    >
                      <div>
                        <IonIcon
                          className="fs-22 me-3"
                          color="primary"
                          icon={checkmarkCircleSharp}
                        />
                      </div>
                      <div>
                        <h6 className="headText mb-0">Raashi</h6>
                        <p className="dataText mb-0">
                          {data?.third_formRaashiEnglish || "-"}
                        </p>
                      </div>
                    </IonCol>
                    <IonCol
                      size="12"
                      className="displayPropAround my-1 ion-align-items-start"
                    >
                      <div>
                        <IonIcon
                          className="fs-22 me-3"
                          color="primary"
                          icon={checkmarkCircleSharp}
                        />
                      </div>
                      <div>
                        <h6 className="headText mb-0">Star/ Nakshatra</h6>
                        <p className="dataText mb-0">
                          {data?.third_formSubRaashiEnglish || "-"}
                        </p>
                      </div>
                    </IonCol>
                    <IonCol
                      size="12"
                      className="displayPropAround my-1 ion-align-items-start"
                    >
                      <div>
                        <IonIcon
                          className="fs-22 me-3"
                          color="primary"
                          icon={checkmarkCircleSharp}
                        />
                      </div>
                      <div>
                        <h6 className="headText mb-0">Manglik</h6>
                        <p className="dataText mb-0">
                          {data?.third_formDoshEnglish || "-"}
                        </p>
                      </div>
                    </IonCol>
                    <IonCol size="12" className="displayProp my-1 ">
                      <div className="displayPropAround ion-align-items-start">
                        <div>
                          <IonIcon
                            className="fs-22 me-3"
                            color="primary"
                            icon={checkmarkCircleSharp}
                          />
                        </div>
                        <div>
                          <h6 className="headText mb-0">Birth Date</h6>
                          <p className="dataText mb-0">
                            {data?.dob
                              ? (data?.dob &&
                                  moment(data?.dob).format("YYYY-MM-DD")) ||
                                "-"
                              : "-"}
                          </p>
                        </div>
                      </div>
                    </IonCol>
                    <IonCol size="12" className="displayProp my-1 ">
                      <div className="displayPropAround ion-align-items-start">
                        <div>
                          <IonIcon
                            className="fs-22 me-3"
                            color="primary"
                            icon={checkmarkCircleSharp}
                          />
                        </div>
                        <div>
                          <h6 className="headText mb-0"> Birth Time</h6>
                          <p className="dataText mb-0">
                            {moment(data?.third_formBirthdetailsTime).format(
                              "hh:mm A"
                            )
                              ? (data?.third_formBirthdetailsTime &&
                                  moment(
                                    data?.third_formBirthdetailsTime
                                  ).format("hh:mm A")) ||
                                "-"
                              : "-"}
                          </p>
                        </div>
                      </div>
                    </IonCol>
                    <IonCol size="12" className="displayProp my-1 ">
                      <div className="displayPropAround ion-align-items-start">
                        <div>
                          <IonIcon
                            className="fs-22 me-3"
                            color="primary"
                            icon={checkmarkCircleSharp}
                          />
                        </div>
                        <div>
                          <h6 className="headText mb-0"> Birth Place</h6>
                          <p className="dataText mb-0">
                            {AppContext?.user?.id === id ? (
                              data?.third_formBirthdetailsDescriptionEnglish ||
                              "-"
                            ) : AppContext?.user?.payment_verified_at &&
                              data?.unlock ? (
                              data?.third_formBirthdetailsDescriptionEnglish ? (
                                data?.third_formBirthdetailsDescriptionEnglish
                              ) : (
                                "*** Not Available ***"
                              )
                            ) : !data?.third_formBirthdetailsDescriptionEnglish ? (
                              <>*** Not Available ***</>
                            ) : (
                              "****** ****** ****"
                            )}
                          </p>
                        </div>
                      </div>
                      {!data?.third_formBirthdetailsDescriptionEnglish ? (
                        <>
                          <button
                            disabled={!data}
                            className="btn btn-danger btn-sm"
                            style={{
                              width: "5.7em",
                              height: "2.5em",
                              padding: "1px 2px",
                            }}
                            onClick={() => {
                              if (!AppContext?.user?.payment_verified_at) {
                                AppContext.setNoDelayPopup(
                                  constant.popups.isSubscribe
                                );
                              } else {
                                if (data?.id) {
                                  if (
                                    !sentRequest?.third_formBirthdetailsDescriptionEnglish
                                  ) {
                                    requestHandler("birthPlace", data?.id);
                                    updateRequestStatus(
                                      "third_formBirthdetailsDescriptionEnglish"
                                    );
                                  }
                                } else {
                                  present({
                                    message: `Loading user details...`,
                                    duration: 1500,
                                    position: "top",
                                    color: "success",
                                    cssClass: "alertToast",
                                  });
                                }
                              }
                            }}
                          >
                            {sentRequest?.third_formBirthdetailsDescriptionEnglish
                              ? "Sent"
                              : "Request"}
                          </button>
                        </>
                      ) : (
                        LockUI()
                      )}
                    </IonCol>
                    <IonCol size="12" className="displayProp my-1 ">
                      <div className="displayPropAround ion-align-items-start">
                        <div>
                          <IonIcon
                            className="fs-22 me-3"
                            color="primary"
                            icon={checkmarkCircleSharp}
                          />
                        </div>
                        <div>
                          <h6 className="headText mb-0">Bio Data Image</h6>
                        </div>
                      </div>
                      {AppContext?.user?.id !== id ? (
                        data?.unlock &&
                        AppContext?.user?.payment_verified_at ? (
                          <div>
                            <IonButton
                              slot="end"
                              type="button"
                              color="primary"
                              fill="outline"
                              onClick={() => {
                                var arr: any = [];
                                data?.profiles?.map((profile: any) => {
                                  if (profile?.type === "birth") {
                                    arr.push(
                                      `${constant.ImageS3URl}/${profile?.folder}/${profile?.name}`
                                    );
                                    setImages(arr);
                                  }
                                });
                                setOpen(true);
                              }}
                            >
                              View
                            </IonButton>
                          </div>
                        ) : (
                          LockUI()
                        )
                      ) : null}
                      {open && (
                        <Lightbox
                          wrapperClassName="ril_outer"
                          imageTitle="Biodata"
                          imageLoadErrorMessage="This image failed to load"
                          onImageLoad={() => {
                            window.dispatchEvent(new Event("resize"));
                          }}
                          mainSrc={images[photoIndex]}
                          nextSrc={images[(photoIndex + 1) % images.length]}
                          prevSrc={
                            images[
                              (photoIndex + images.length - 1) % images.length
                            ]
                          }
                          onCloseRequest={() => setOpen(false)}
                          onMovePrevRequest={() =>
                            setPhotoIndex(
                              (photoIndex + images.length - 1) % images.length
                            )
                          }
                          onMoveNextRequest={() =>
                            setPhotoIndex((photoIndex + 1) % images.length)
                          }
                        />
                      )}
                    </IonCol>
                  </IonRow>
                </div>
              </IonAccordion>
              <IonAccordion value="six">
                <IonItem slot="header">
                  <IonLabel className="accordion-expanding">
                    Family Information
                  </IonLabel>
                </IonItem>
                <div
                  className="ion-padding datas"
                  slot="content"
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                >
                  <IonRow className="ion-align-items-center">
                    <IonCol size="12" className="displayProp my-1 ">
                      <div className="displayPropAround ion-align-items-start">
                        <div>
                          <IonIcon
                            className="fs-22 me-3"
                            color="primary"
                            icon={checkmarkCircleSharp}
                          />
                        </div>
                        <div>
                          <h6 className="headText mb-0">
                            Total no of family members
                          </h6>
                          <p className="dataText mb-0">
                            {data?.fifth_formFamilyMember
                              ? data?.fifth_formFamilyMember || "-"
                              : "-"}
                          </p>
                        </div>
                      </div>
                    </IonCol>
                    <IonCol size="12" className="displayProp my-1 ">
                      <div className="displayPropAround ion-align-items-start">
                        <div>
                          <IonIcon
                            className="fs-22 me-3"
                            color="primary"
                            icon={checkmarkCircleSharp}
                          />
                        </div>
                        <div>
                          <h6 className="headText mb-0">Father's Detail</h6>
                          <p className="dataText mb-0">
                            {`${data?.fifth_formFatherNameEnglish || "-"}  / ${
                              data?.fifth_formFatherEmployedInEnglish || "-"
                            }`
                              ? `${
                                  data?.fifth_formFatherNameEnglish || "-"
                                }   / ${
                                  data?.fifth_formFatherEmployedInEnglish || "-"
                                }`
                              : "-"}
                          </p>
                        </div>
                      </div>
                    </IonCol>
                    <IonCol size="12" className="displayProp my-1 ">
                      <div className="displayPropAround ion-align-items-start">
                        <div>
                          <IonIcon
                            className="fs-22 me-3"
                            color="primary"
                            icon={checkmarkCircleSharp}
                          />
                        </div>
                        <div>
                          <h6 className="headText mb-0">Father's Mobile</h6>
                          <p className="dataText mb-0">
                            {AppContext?.user?.id === id ? (
                              data?.fifth_formFatherContactNumberMobile || "-"
                            ) : AppContext?.user?.payment_verified_at &&
                              data?.unlock &&
                              profileVisiblity() ? (
                              data?.fifth_formFatherContactNumberMobile ? (
                                data?.fifth_formFatherContactNumberMobile
                              ) : (
                                "*** Not Available ***"
                              )
                            ) : !data?.fifth_formFatherContactNumberMobile ? (
                              <>*** Not Available ***</>
                            ) : (
                              "****** ****** ****"
                            )}
                            {/* {AppContext?.user?.id === id
                              ? data?.fifth_formFatherContactNumberMobile || "-"
                              : AppContext?.user?.payment_verified_at &&
                                data?.unlock
                              ? data?.fifth_formFatherContactNumberMobile || "-"
                              : "****** ****** ****"} */}
                          </p>
                        </div>
                      </div>
                      {!data?.fifth_formFatherContactNumberMobile ? (
                        <>
                          <button
                            disabled={!data}
                            className="btn btn-danger btn-sm"
                            style={{
                              width: "5.7em",
                              height: "2.5em",
                              padding: "1px 2px",
                            }}
                            onClick={() => {
                              if (!AppContext?.user?.payment_verified_at) {
                                AppContext.setNoDelayPopup(
                                  constant.popups.isSubscribe
                                );
                              } else {
                                if (data?.id) {
                                  if (
                                    !sentRequest?.fifth_formFatherContactNumberMobile
                                  ) {
                                    requestHandler("fatherMobile", data?.id);
                                    updateRequestStatus(
                                      "fifth_formFatherContactNumberMobile"
                                    );
                                  }
                                } else {
                                  present({
                                    message: `Loading user details...`,
                                    duration: 1500,
                                    position: "top",
                                    color: "success",
                                    cssClass: "alertToast",
                                  });
                                }
                              }
                            }}
                          >
                            {sentRequest?.fifth_formFatherContactNumberMobile
                              ? "Sent"
                              : "Request"}
                          </button>
                        </>
                      ) : (
                        LockUI()
                      )}
                    </IonCol>
                    <IonCol size="12" className="displayProp my-1 ">
                      <div className="displayPropAround ion-align-items-start">
                        <div>
                          <IonIcon
                            className="fs-22 me-3"
                            color="primary"
                            icon={checkmarkCircleSharp}
                          />
                        </div>
                        <div>
                          <h6 className="headText mb-0">Mother's Detail</h6>
                          <p className="dataText mb-0">
                            {`${data?.fifth_formMotherNameEnglish || "-"}  / ${
                              data?.fifth_formMotherEmployedInEnglish || "-"
                            }`
                              ? `${
                                  data?.fifth_formMotherNameInEnglish || "-"
                                }  / ${
                                  data?.fifth_formMotherEmployedInEnglish || "-"
                                }`
                              : "-"}
                          </p>
                        </div>
                      </div>
                    </IonCol>
                    <IonCol size="12" className="displayProp my-1 ">
                      <div className="displayPropAround ion-align-items-start">
                        <div>
                          <IonIcon
                            className="fs-22 me-3"
                            color="primary"
                            icon={checkmarkCircleSharp}
                          />
                        </div>
                        <div>
                          <h6 className="headText mb-0">Mother's Mobile</h6>
                          <p className="dataText mb-0">
                            {/* {AppContext?.user?.id === id
                              ? data?.fifth_formMotherContactNumberMobile || "-"
                              : AppContext?.user?.payment_verified_at &&
                                data?.unlock
                              ? data?.fifth_formMotherContactNumberMobile || "-"
                              : "****** ****** ****"} */}
                            {AppContext?.user?.id === id ? (
                              data?.fifth_formMotherContactNumberMobile || "-"
                            ) : AppContext?.user?.payment_verified_at &&
                              data?.unlock &&
                              profileVisiblity() ? (
                              data?.fifth_formMotherContactNumberMobile ? (
                                data?.fifth_formMotherContactNumberMobile
                              ) : (
                                "*** Not Available ***"
                              )
                            ) : !data?.fifth_formMotherContactNumberMobile ? (
                              <>*** Not Available ***</>
                            ) : (
                              "****** ****** ****"
                            )}
                          </p>
                        </div>
                      </div>
                      {!data?.fifth_formMotherContactNumberMobile ? (
                        <>
                          <button
                            disabled={!data}
                            className="btn btn-danger btn-sm"
                            style={{
                              width: "5.7em",
                              height: "2.5em",
                              padding: "1px 2px",
                            }}
                            onClick={() => {
                              if (!AppContext?.user?.payment_verified_at) {
                                AppContext.setNoDelayPopup(
                                  constant.popups.isSubscribe
                                );
                              } else {
                                if (data?.id) {
                                  if (
                                    !sentRequest?.fifth_formMotherContactNumberMobile
                                  ) {
                                    requestHandler("motherMobile", data?.id);
                                    updateRequestStatus(
                                      "fifth_formMotherContactNumberMobile"
                                    );
                                  }
                                } else {
                                  present({
                                    message: `Loading user details...`,
                                    duration: 1500,
                                    position: "top",
                                    color: "success",
                                    cssClass: "alertToast",
                                  });
                                }
                              }
                            }}
                          >
                            {sentRequest?.fifth_formMotherContactNumberMobile
                              ? "Sent"
                              : "Request"}
                          </button>
                        </>
                      ) : (
                        LockUI()
                      )}
                    </IonCol>
                    <IonCol size="12" className="displayProp my-1 ">
                      <div className="displayPropAround ion-align-items-start">
                        <div>
                          <IonIcon
                            className="fs-22 me-3"
                            color="primary"
                            icon={checkmarkCircleSharp}
                          />
                        </div>
                        <div>
                          <h6 className="headText mb-0">Family type</h6>
                          <p className="dataText mb-0">
                            {data?.fifth_formFamilyTypeEnglish || "-"
                              ? data?.fifth_formFamilyTypeEnglish || "-"
                              : "-"}
                          </p>
                        </div>
                      </div>
                    </IonCol>
                    <IonCol size="12" className="displayProp my-1 ">
                      <div className="displayPropAround ion-align-items-start">
                        <div>
                          <IonIcon
                            className="fs-22 me-3"
                            color="primary"
                            icon={checkmarkCircleSharp}
                          />
                        </div>
                        <div>
                          <h6 className="headText mb-0"> Residential status</h6>
                          <p className="dataText mb-0">
                            {data?.fifth_formResidentalStatusEnglish || "-"
                              ? data?.fifth_formResidentalStatusEnglish || "-"
                              : "-"}
                          </p>
                        </div>
                      </div>
                    </IonCol>
                    <IonCol
                      size="12"
                      className="displayPropAround my-1 ion-align-items-start"
                    >
                      <div>
                        <IonIcon
                          className="fs-22 me-3"
                          color="primary"
                          icon={checkmarkCircleSharp}
                        />
                      </div>
                      <div>
                        <h6 className="headText mb-0"> Family Status</h6>
                        <p className="dataText mb-0">
                          {data?.fifth_formFamilyStatusEnglish || "-"}
                        </p>
                      </div>
                    </IonCol>
                  </IonRow>
                </div>
              </IonAccordion>
              {/* rohit */}
              <IonAccordion value="seven">
                <IonItem slot="header">
                  <IonLabel className="accordion-expanding">
                    Partner Preference
                  </IonLabel>
                </IonItem>
                <div
                  className="ion-padding datas"
                  slot="content"
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                >
                  <IonRow className="ion-align-items-center">
                    <IonCol size="12" className="displayProp my-1 ">
                      <div className="displayPropAround ion-align-items-start">
                        <div>
                          <IonIcon
                            className="fs-22 me-3"
                            color="primary"
                            icon={checkmarkCircleSharp}
                          />
                        </div>
                        <div>
                          <h6 className="headText mb-0">Age (In Year)</h6>
                          <p className="dataText mb-0">
                            {data?.partner_preferenceAgeStart
                              ? data?.partner_preferenceAgeStart
                              : "-"}{" "}
                            to{" "}
                            {data?.partner_preferenceAgeEnd
                              ? data?.partner_preferenceAgeEnd
                              : "-"}
                          </p>
                        </div>
                      </div>
                    </IonCol>
                    <IonCol size="12" className="displayProp my-1 ">
                      <div className="displayPropAround ion-align-items-start">
                        <div>
                          <IonIcon
                            className="fs-22 me-3"
                            color="primary"
                            icon={checkmarkCircleSharp}
                          />
                        </div>
                        <div>
                          <h6 className="headText mb-0">State</h6>
                          <p className="dataText mb-0">
                            {data?.partner_preferenceStateEnglish
                              ? data?.partner_preferenceStateEnglish || "-"
                              : "-"}
                          </p>
                        </div>
                      </div>
                    </IonCol>
                    <IonCol size="12" className="displayProp my-1 ">
                      <div className="displayPropAround ion-align-items-start">
                        <div>
                          <IonIcon
                            className="fs-22 me-3"
                            color="primary"
                            icon={checkmarkCircleSharp}
                          />
                        </div>
                        <div>
                          <h6 className="headText mb-0">Sect</h6>
                          <p className="dataText mb-0">
                            {data?.partner_preferenceSectEnglish
                              ? data?.partner_preferenceSectEnglish || "-"
                              : "-"}
                          </p>
                        </div>
                      </div>
                    </IonCol>
                    <IonCol size="12" className="displayProp my-1 ">
                      <div className="displayPropAround ion-align-items-start">
                        <div>
                          <IonIcon
                            className="fs-22 me-3"
                            color="primary"
                            icon={checkmarkCircleSharp}
                          />
                        </div>
                        <div>
                          <h6 className="headText mb-0">Marital status</h6>
                          <p className="dataText mb-0">
                            {data?.partner_preferenceMaritalStatusEnglish
                              ? data?.partner_preferenceMaritalStatusEnglish ||
                                "-"
                              : "-"}
                          </p>
                        </div>
                      </div>
                    </IonCol>
                    <IonCol size="12" className="displayProp my-1 ">
                      <div className="displayPropAround ion-align-items-start">
                        <div>
                          <IonIcon
                            className="fs-22 me-3"
                            color="primary"
                            icon={checkmarkCircleSharp}
                          />
                        </div>
                        <div>
                          <h6 className="headText mb-0"> Employed In</h6>
                          <p className="dataText mb-0">
                            {data?.partner_preferenceEmployedInEnglish
                              ? data?.partner_preferenceEmployedInEnglish || "-"
                              : "-"}
                          </p>
                        </div>
                      </div>
                    </IonCol>
                    <IonCol size="12" className="displayProp my-1 ">
                      <div className="displayPropAround ion-align-items-start">
                        <div>
                          <IonIcon
                            className="fs-22 me-3"
                            color="primary"
                            icon={checkmarkCircleSharp}
                          />
                        </div>
                        <div>
                          <h6 className="headText mb-0">Manglik Status</h6>
                          <p className="dataText mb-0">
                            {data?.partner_preferenceManglikStatusEnglish
                              ? data?.partner_preferenceManglikStatusEnglish ||
                                "-"
                              : "-"}
                          </p>
                        </div>
                      </div>
                    </IonCol>
                    <IonCol size="12" className="displayProp my-1 ">
                      <div className="displayPropAround ion-align-items-start">
                        <div>
                          <IonIcon
                            className="fs-22 me-3"
                            color="primary"
                            icon={checkmarkCircleSharp}
                          />
                        </div>
                        <div>
                          <h6 className="headText mb-0">Candidate Income*</h6>
                          <p className="dataText mb-0">
                            {data?.partner_preferenceCandidateIncomeEnglish
                              ? data?.partner_preferenceCandidateIncomeEnglish ||
                                "-"
                              : "-"}
                          </p>
                        </div>
                      </div>
                    </IonCol>
                  </IonRow>
                </div>
              </IonAccordion>
            </IonAccordionGroup>
          </div>
          <div>
            {!isDesktop && (
              <IonPopover
                isOpen={showPopover}
                event={event}
                onDidDismiss={handlePopoverClose}
                style={{ "--max-width": "35vw" }}
                className="newusercardpopover"
                side={"bottom"}
                alignment={"center"}
              >
                <div className="popover-content">
                  <IonList className="m-0 p-0 optionParent">
                    <IonItem
                      onClick={() => {
                        if (!AppContext?.user?.payment_verified_at) {
                          AppContext.setNoDelayPopup(
                            constant.popups.isSubscribe
                          );
                        } else {
                          setAlertType("report_user");
                          setShowAlert(true);
                        }
                      }}
                      style={{ fontWeight: "600", fontSize: "11px" }}
                    >
                      <img src={report} className="me-1" />
                      <span>Report</span>
                    </IonItem>
                    {!data?.shadiDoneByMe && (
                      <IonItem
                        onClick={() => {
                          if (!AppContext?.user?.payment_verified_at) {
                            AppContext.setNoDelayPopup(
                              constant.popups.isSubscribe
                            );
                          } else {
                            setAlertType("shadi_done");
                            setShowAlert(true);
                          }
                        }}
                        style={{ fontWeight: "600", fontSize: "11px" }}
                      >
                        <img src={weddingrings} className="me-1" />
                        <span>Shadi Done</span>
                      </IonItem>
                    )}
                    {!data?.blockedUserIdByMe && (
                      <IonItem
                        onClick={() => {
                          setAlertType("block_user");
                          setShowAlert(true);
                        }}
                        style={{ fontWeight: "600", fontSize: "11px" }}
                      >
                        <img src={blockuser} className="me-1" />
                        <span>Block User</span>
                      </IonItem>
                    )}
                  </IonList>
                </div>
              </IonPopover>
            )}
          </div>
          <IonAlert
            isOpen={showAlert}
            onDidDismiss={() => setShowAlert(false)}
            cssClass="actionAlert123"
            header={`${
              alertType === "cancel_request"
                ? "Are you sure you want to remove interest?"
                : alertType === "shadi_done"
                ? "Are you sure you want to Mark User as Shadi Done?"
                : alertType === "block_user"
                ? "Are you sure you want to Block this User?"
                : alertType === "report_user"
                ? "Are you sure you want to Report this User?"
                : "Are you sure you want to confirm"
            }`}
            buttons={[
              {
                text: "No",
                role: "cancel",
                cssClass: "alertButton",
              },
              {
                text: "Yes",
                handler: handleAlert,
                cssClass: "alertButton",
              },
            ]}
          />
        </IonContent>
      </IonModal>
      <IonAlert
        isOpen={privateAlert}
        onDidDismiss={() => setPrivateAlert(false)}
        header={"Private Profile"}
        message={"This user's profile is private. Please send interest first."}
        buttons={[
          {
            text: "OK",
            handler: () => setPrivateAlert(false),
          },
        ]}
        cssClass="private-alert"
      />
    </>
  );
}
export default Details;
