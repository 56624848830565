import {
  IonCard,
  IonCheckbox,
  IonContent,
  IonHeader,
  IonIcon,
  IonImg,
  IonItem,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonAlert,
  useIonRouter,
} from "@ionic/react";
import {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { IonLabel } from "@ionic/react";
import { IonCol, IonGrid, IonRow } from "@ionic/react";
import { IonInput, IonList, IonButton } from "@ionic/react";
import { useHistory, useLocation } from "react-router-dom";
import appContext from "../../context/AppContext";
import { useForm } from "react-hook-form";
import {
  authorizeMe,
  getCurrentState,
  getStateListAPI,
  newAccount,
  signUp,
  verifyReferral,
  profileList,
  referralApi,
  getGoliveFormDetails,
} from "../../service";
import { PushNotifications, Token } from "@capacitor/push-notifications";
import vector2 from "../../assets/images/Vector(2).png";
import vector1 from "../../assets/images/Vector(1).png";
import { isPlatform } from "@ionic/react";
import CustomSelect from "../shared/CustomSelect";
import constant from "../../constant";
import logo from "../../assets/images/logo.png";
import {
  addCircleSharp,
  arrowBackOutline,
  closeCircleSharp,
} from "ionicons/icons";

const Signup = () => {
  const history = useHistory();
  const AppContext = useContext(appContext);
  const { state }: any = useLocation();
  const usernameRef = useRef<any>(null);
  const [DeviceToken, setDeviceToken] = useState("");
  const [privacy, setPrivacy] = useState<any>();
  const [userList, setUserList] = useState([]);
  const [SelectGender, setSelectGender] = useState<any>(null);
  const [stateList, setStateList]: any = useState([]);
  const [SelectGenderError, setSelectGenderError] = useState<any>(false);
  const [noOfChild, setnoOfChild] = useState<any>(false);
  const [referral, setReferral] = useState<any>(false);
  const ionRouter: any = useIonRouter();
  const [sectOption, setSectOption] = useState<any[]>([]);
  const [presentAlert, present] = useIonAlert();
  const [referralCode, setReferralCode] = useState<any>("");
  const [backData, setBackData] = useState<any>({});
  const [referralError, setReferralError] = useState<any>({
    error: "",
    show: false,
  });
  const [childLivingStatus, setChildLivingStatus] = useState<any>(false);
  const profileArray = [
    { label: "Self(स्वयं)", value: "Self(स्वयं)" },
    { label: "Son(पुत्र)", value: "Son(पुत्र)" },
    { label: "Daughter(पुत्री)", value: "Daughter(पुत्री)" },
    { label: "Brother(भाई)", value: "Brother(भाई)" },
    { label: "Sister(बहन)", value: "Sister(बहन)" },
    { label: "Relative(परिवार)", value: "Relative(परिवार)" },
    { label: "Friend(मित्र)", value: "Friend(मित्र)" },
  ];
  const ageArray = [];
  for (let index = 18; index < 81; index++) {
    ageArray.push({ label: index + " yrs", value: index + " yrs" });
  }
  const childrenArray = [];
  for (let index = 0; index < 6; index++) {
    childrenArray.push({ label: index.toString(), value: index.toString() });
  }

  let initialValues = {
    name: "",
    age: "",
    profile: "",
    sect: "",
    marritalStatus: "",
    gender: "",
    state: "",
    first_formNoOfChildren: "",
    childrenLivingStatus: "",
    referral_code: "",
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    getValues,
    control,
    watch,
  } = useForm({ defaultValues: initialValues });
  var data: any = localStorage.getItem("responseData");
  var res = JSON.parse(data);
  localStorage.setItem("lastPage", window.location.pathname);

  const watchStatus = watch("marritalStatus");
  const watchLivingStatus = watch("first_formNoOfChildren");

  useEffect(() => {
    if (!res?.newUser) {
      document.addEventListener("ionBackButton", (ev: any) => {
        if (!res?.newUser) {
          history.replace({
            pathname: "/user-list",
            state: {
              accessToken: res?.accessToken,
              id: res?.users.id,
              mobile: res?.users?.mobile,
              newUser: res.newUser,
            },
          });
        }
      });
      return () => {
        document.removeEventListener("ionBackButton", () => {});
      };
    } else {
      document.addEventListener("ionBackButton", (ev: any) => {
        console.log("new user");

        if (res?.newUser) {
          history.push({
            pathname: "/signup-form",
            state: {
              accessToken: res?.accessToken,
              id: res?.users.id,
              mobile: res?.users?.mobile,
              newUser: res.newUser,
            },
          });
        }
      });
      return () => {
        document.removeEventListener("ionBackButton", () => {});
      };
    }
  }, []);

  useEffect(() => {
    var value: any = getValues("marritalStatus");
    var u: any = getValues("first_formNoOfChildren");
    if (value) {
      if (value?.value !== "Unmarried") {
        setnoOfChild(true);
      } else {
        setnoOfChild(false);
      }
    }
    if (u) {
      if (u?.value > 0) {
        setChildLivingStatus(true);
      } else {
        setChildLivingStatus(false);
      }
    }
  }, [watchStatus, watchLivingStatus, getValues]);

  var newMobile = localStorage.getItem("mobile");

  const getStateList = useCallback(async () => {
    authorizeMe(state.accessToken);
    let stateList: any = await getStateListAPI({ countryId: "India" });
    let test = stateList?.data?.data?.map((i: any) => {
      i.label = i?.english;
      i.value = i?.english;
      return i;
    });
    setStateList(test);
    getSectOption();
    let currentstate: any = await getCurrentState();
    setValue("state", currentstate?.data?.regionName);
  }, [setValue, state?.accessToken ? state?.accessToken : res?.accessToken]);

  const getSectOption = async () => {
    const res = await getGoliveFormDetails();
    const arr: any[] = [];
    res?.data?.data?.sects?.forEach((dataSect: any) => {
      if (dataSect?.status) {
        arr.push({
          label: dataSect?.english,
          value: dataSect?.english,
        });
      }
    });
    setSectOption(arr);
  };

  useEffect(() => {
    getStateList();
    PushNotifications.register();
    PushNotifications.addListener("registration", (token: Token) => {
      setDeviceToken(token.value);
    });
  }, [DeviceToken, getStateList]);

  // document.addEventListener('ionBackButton', (ev: any) => {
  //     ev.detail.register(-1, () => {
  //         if (ionRouter.routeInfo?.pathname === "/signup-form") {
  //             if (userList?.length > 0) {
  //                 presentAlert({
  //                     subHeader: 'Are you sure want to end process ?',
  //                     cssClass: 'actionAlert',
  //                     buttons: [

  //                         {
  //                             text: 'Yes',
  //                             role: 'confirm',
  //                             handler: async () => {
  //                                 history.replace('/signup-form')
  //                             },
  //                         },
  //                         {
  //                             text: 'no',
  //                             role: 'cancel',

  //                         },
  //                     ],
  //                 })
  //             } else {

  //             }
  //         }
  //     });
  // });

  useEffect(() => {
    const getUserlist = async () => {
      if (state) {
        var response = await profileList(
          { mobile: state?.mobile },
          state?.accessToken
        );
        setUserList(response?.data?.data);
      } else {
        var response = await profileList(
          { mobile: newMobile },
          res?.accessToken
        );
        setUserList(response?.data?.data);
      }
    };
    if (state?.accessToken || res?.accessToken) {
      getUserlist();
    }
  }, [state?.mobile, state?.accessToken]);

  const onSubmit = async (data: any) => {
    usernameRef.current?.focus();
    if (SelectGender === null) {
      usernameRef.current?.focus();
      setSelectGenderError(true);
      return;
    } else if (!privacy) {
      usernameRef.current?.focus();
      setPrivacy(false);
      return;
    } else {
      AppContext.setLoading(true);
      authorizeMe(state.accessToken);
      AppContext.setUser({ token: state.accessToken });
      var platform = isPlatform("desktop");
      var obj = {
        nameEnglish: data.name,
        first_formGenderEnglish: SelectGender,
        third_formSectEnglish: data.sect.value.split("(")[0],
        first_formMaritalStatusEnglish: data.marritalStatus.value,
        _age: data?.age?.value?.split(" ", 1)[0],
        first_formProfileEnglish: data.profile.value.split("(")[0],
        app_id: platform ? null : DeviceToken,
        second_formStateEnglish: data?.state?.english || data.state,
        first_formNoOfChildren: parseInt(data?.first_formNoOfChildren?.value),
        first_formChildrenLivingStatusEnglish:
          data?.childrenLivingStatus?.value,
        user_referral_id: referralCode,
        web_app: DeviceToken ? "app" : "website",
      };

      if (state.newUser) {
        var response = await signUp(obj);
        if (response?.data?.status) {
          localStorage.setItem(constant.NewAccount, "YES");
          authorizeMe(state.accessToken);
          AppContext.setUser({
            ...response.data.data.user,
            token: state.accessToken,
          });
          localStorage.setItem("refferal_user", "yes");
          AppContext.setLoading(false);
          AppContext?.setIsLoggedIn(true);
          reset();
          window.location.assign("/");
          history.replace("/");
        }
      } else {
        var response2 = await newAccount(obj);

        if (response2?.data?.status) {
          localStorage.setItem(constant.NewAccount, "YES");
          authorizeMe(response2.data.data.accessToken);
          AppContext.setUser({
            ...response2.data.data.user,
            token: response2.data.data.accessToken,
          });
          localStorage.setItem("refferal_user", "yes");
          AppContext.setLoading(false);
          AppContext?.setIsLoggedIn(true);
          reset();
          window.location.assign("/");
          history.replace("/");
        }
      }
    }
  };

  const mediaQuery = window.matchMedia("(min-width: 576px)");

  useEffect(() => {
    const dataString = localStorage.getItem("responseData");
    let data: any = {};

    if (dataString) {
      try {
        data = JSON.parse(dataString);
      } catch (e) {
        console.error("Failed to parse responseData from localStorage:", e);
      }
    }
    setBackData(data);
  }, []);

  return (
    <IonPage>
      <div
        className={
          mediaQuery.matches ? "test-background-image" : "bg UserListBody"
        }
      >
        {/* excluding phone */}
        {mediaQuery.matches ? (
          <div className={mediaQuery.matches ? "auth-content" : ""}>
            <div>
              <img
                loading="lazy"
                className="web-logo"
                src={logo}
                alt="logo-image"
              ></img>
              <div style={{ marginTop: "20px" }} className="web-body-text ">
                <IonLabel>
                  <h1 style={{ width: 500 }}>
                    <b>
                      Best Matrimony Site Used By Millions of Jainism Members .
                    </b>
                  </h1>
                </IonLabel>
              </div>
            </div>
            <div
              className="authbottomCover auth-web-bottom"
              style={
                mediaQuery.matches ? { paddingTop: 22 } : { paddingTop: 0 }
              }
            >
              <IonHeader>
                <IonToolbar color="primary">
                  {mediaQuery.matches && (
                    <div
                      onClick={() => {
                        presentAlert({
                          subHeader: "Are you sure want to end process ?",
                          cssClass: "actionAlert",
                          buttons: [
                            {
                              text: "Yes",
                              role: "confirm",
                              handler: async () => {
                                history.goBack();
                              },
                            },
                            {
                              text: "no",
                              role: "cancel",
                            },
                          ],
                        });
                      }}
                      slot="start"
                      className="backBtn"
                    >
                      <IonButton type="submit" className="px-1">
                        <IonIcon icon={arrowBackOutline} color="light" />
                      </IonButton>
                    </div>
                  )}
                  <IonTitle style={{ textAlign: "center" }}>
                    <b>Create Account</b>
                  </IonTitle>
                </IonToolbar>
              </IonHeader>
              <form onSubmit={handleSubmit(onSubmit)}>
                <IonList
                  class="p-20"
                  style={
                    mediaQuery.matches
                      ? { paddingBottom: "10px" }
                      : { paddingBottom: "70px" }
                  }
                >
                  <IonGrid>
                    <IonLabel
                      position="stacked"
                      style={{ color: "#640000" }}
                      className="fs-16 labelItem"
                    >
                      Profile Created For{" "}
                      <span className="fs-12">(प्रोफाइल किसके लिए)</span>
                      <span className="errorText m-0">*</span>
                    </IonLabel>
                    <CustomSelect
                      isSearchable={false}
                      options={profileArray}
                      control={control}
                      required={true}
                      name={"profile"}
                      placeholder={"Select Profile created for"}
                    />
                    {errors.profile && errors.profile.type === "required" && (
                      <p className="errorText ms-0 mt-2">Profile is required</p>
                    )}
                  </IonGrid>
                  <IonGrid>
                    <IonLabel position="stacked" className="fs-16 labelItem">
                      Candidate Name{" "}
                      <span className="fs-12">(प्रत्याशी का नाम)</span>
                      <span className="errorText m-0">*</span>
                    </IonLabel>
                    <IonInput
                      placeholder={"Candidate Full Name"}
                      className="border-3 Color-Placeholder"
                      {...register("name", { required: true })}
                    ></IonInput>
                  </IonGrid>
                  {errors.name && errors.name.type === "required" && (
                    <p className="errorText ms-1"> Name is required</p>
                  )}
                  <IonGrid>
                    <IonLabel position="stacked" className="fs-16 labelItem">
                      Candidate Age{" "}
                      <span className="fs-12">(प्रत्याशी की उम्र)</span>
                      <span className="errorText m-0">*</span>
                    </IonLabel>
                    <CustomSelect
                      isSearchable={false}
                      options={ageArray}
                      control={control}
                      required={true}
                      name={"age"}
                      placeholder={"Select Age"}
                    />
                    {errors.age && errors.age.type === "required" && (
                      <p className="errorText ms-0 mt-2">Age is required</p>
                    )}
                  </IonGrid>
                  <IonGrid>
                    <IonRow>
                      <IonCol>
                        <IonLabel
                          className="fs-16 labelItem pl-0"
                          position="stacked"
                        >
                          Gender<span className="errorText m-0">*</span>
                        </IonLabel>
                      </IonCol>
                    </IonRow>
                    {/* <IonRadioGroup value="custom-checked"   > */}
                    <IonRow>
                      <IonCol
                        size="6"
                        style={{ paddingRight: 20 }}
                        onClick={() => setSelectGender("Male")}
                      >
                        <div
                          className="genderImg"
                          style={{
                            border:
                              SelectGender === "Male"
                                ? "1px solid #ee453d"
                                : "",
                          }}
                        >
                          <img loading="lazy" src={vector1} />
                          <p className="dark fs-15 font-weight-500 ion-text-center m-0">
                            Male
                          </p>
                          <p className="dark fs-12 font-weight-500 ion-text-center m-0">
                            (युवक)
                          </p>
                        </div>
                        {/* <IonRadio slot="start" className='check-round m-0' value='Male'  {...register("gender")}></IonRadio >
                                            <IonLabel className='ml-10'>Male</IonLabel> */}
                      </IonCol>
                      <IonCol
                        size="6"
                        onClick={() => setSelectGender("Female")}
                      >
                        <div
                          className="genderImg"
                          style={{
                            border:
                              SelectGender === "Female"
                                ? "1px solid #ee453d"
                                : "",
                          }}
                        >
                          <img loading="lazy" src={vector2} />
                          <p className="dark fs-15 font-weight-500 ion-text-center m-0">
                            Female
                          </p>
                          <p className="dark fs-12 font-weight-500 ion-text-center m-0">
                            (युवती)
                          </p>
                        </div>
                        {/* <IonRadio slot="start" className='check-round m-0' value='Female'  {...register("gender")}></IonRadio >
                                            <IonLabel className='ml-10'>Female</IonLabel> */}
                      </IonCol>
                      {SelectGenderError && (
                        <p className="errorText  ms-0 mt-2">
                          Gender is required
                        </p>
                      )}
                    </IonRow>
                    {/* </IonRadioGroup> */}
                  </IonGrid>
                  <IonGrid>
                    <IonRow>
                      <IonCol size="12">
                        <IonLabel
                          position="stacked"
                          className="fs-16 labelItem"
                        >
                          Sect <span className="fs-12">(पंथ)</span>
                          <span className="errorText m-0">*</span>
                        </IonLabel>
                        <CustomSelect
                          options={sectOption}
                          control={control}
                          required={true}
                          name={"sect"}
                          placeholder={"Select Sect"}
                          isSearchable={false}
                        />
                        {errors.sect && errors.sect.type === "required" && (
                          <p className="errorText  ms-0 mt-2">
                            Sector is required
                          </p>
                        )}
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                  <IonGrid>
                    <IonRow>
                      <IonCol size="12">
                        <IonLabel
                          position="stacked"
                          className="fs-16 labelItem"
                        >
                          Marital Status{" "}
                          <span className="fs-12">(वैवाहिक स्थिति)</span>
                          <span className="errorText m-0">*</span>
                        </IonLabel>
                        <CustomSelect
                          options={[
                            { label: "Unmarried", value: "Unmarried" },
                            { label: "Widow/Widower", value: "Widow/Widower" },
                            { label: "Divorced", value: "Divorced" },
                            {
                              label: "Awaiting divorce",
                              value: "Awaiting divorce",
                            },
                          ]}
                          control={control}
                          required={true}
                          name={"marritalStatus"}
                          placeholder={"Select Marital status"}
                          isSearchable={false}
                        />
                        {errors.marritalStatus &&
                          errors.marritalStatus.type === "required" && (
                            <p className="errorText  ms-0 mt-2">
                              Marital Status is required
                            </p>
                          )}
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                  {noOfChild && (
                    <>
                      <IonGrid>
                        <IonLabel
                          position="stacked"
                          className="fs-16 labelItem"
                        >
                          No. of childrens
                          <span className="errorText m-0">*</span>
                        </IonLabel>
                        <CustomSelect
                          options={childrenArray}
                          control={control}
                          required={false}
                          name={"first_formNoOfChildren"}
                          placeholder={"Select No. of childrens"}
                          isSearchable={false}
                        />
                      </IonGrid>
                      {errors.first_formNoOfChildren &&
                        errors.first_formNoOfChildren.type === "required" && (
                          <p className="errorText ms-1">
                            {" "}
                            No. of childrens is required
                          </p>
                        )}
                    </>
                  )}
                  {noOfChild && childLivingStatus && (
                    <IonRow>
                      <IonCol size="12">
                        <IonLabel
                          position="stacked"
                          className="fs-16 labelItem"
                        >
                          Living Status<span className="errorText m-0">*</span>
                        </IonLabel>
                        <CustomSelect
                          options={[
                            { label: "Live with me", value: "Live with me" },
                            {
                              label: "Not live with me",
                              value: "Not live with me",
                            },
                          ]}
                          control={control}
                          required={false}
                          name={"childrenLivingStatus"}
                          placeholder={"Select Liiving status"}
                          isSearchable={false}
                        />
                        {errors.childrenLivingStatus &&
                          errors.childrenLivingStatus.type === "required" && (
                            <p className="errorText  ms-0 mt-2">
                              Living Status is required
                            </p>
                          )}
                      </IonCol>
                    </IonRow>
                  )}
                  <IonGrid>
                    <IonRow>
                      <IonCol size="12">
                        <IonLabel
                          position="stacked"
                          className="fs-16 labelItem"
                        >
                          State <span className="fs-12">(राज्य)</span>
                          <span className="errorText m-0">*</span>
                        </IonLabel>
                        <CustomSelect
                          options={stateList}
                          control={control}
                          required={true}
                          name={"state"}
                          placeholder={"Select State"}
                          isSearchable={true}
                        />
                        {errors?.state && (
                          <p className="errorText  ms-0 mt-2">
                            State is required
                          </p>
                        )}
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                  {referral && (
                    <IonGrid>
                      <div style={{ width: "100%", marginBottom: "8px" }}>
                        <IonLabel
                          style={{
                            lineHeight: "20px",
                            fontSize: 11,
                            marginBottom: 0,
                            marginLeft: 3,
                            color: "#000",
                          }}
                        >
                          <b> Referral Code </b>
                        </IonLabel>
                        <IonItem>
                          <IonInput
                            placeholder={"Enter your Referral Code here..."}
                            className="referral_input me-3 outlined-input"
                            {...register("referral_code")}
                          ></IonInput>
                          <IonButton
                            slot="end"
                            onClick={async (event) => {
                              event.stopPropagation();
                              const watchReferral = watch("referral_code");
                              var res = await verifyReferral({
                                referralId: watchReferral,
                              });
                              if (res?.data?.status) {
                                setReferralCode(res?.data?.data?.id);
                                setReferralError({
                                  show: true,
                                  error: `Referral Code added Successfully of  user  ${res?.data?.data?.nameEnglish}.`,
                                });
                              } else {
                                setReferralError({
                                  show: true,
                                  error: "Referral Code not Matched.",
                                });
                              }
                            }}
                            style={{ marginBottom: "14px" }}
                          >
                            Apply
                          </IonButton>
                        </IonItem>
                      </div>
                      {/* <div
                            slot="end"
                            className="backBtn"
                            onClick={async (event) => {
                              event.stopPropagation();
                              const watchReferral = watch("referral_code");
                              var res = await verifyReferral({
                                referralId: watchReferral,
                              });
                              if (res?.data?.status) {
                                setReferralCode(res?.data?.data?.id);
                                setReferralError({
                                  show: true,
                                  error: `Referral Code added Successfully of  user  ${res?.data?.data?.nameEnglish}.`,
                                });
                              } else {
                                setReferralError({
                                  show: true,
                                  error: "Referral Code not Matched.",
                                });
                              }
                            }}
                          >
                            <IonButton style={{ marginBottom: "14px" }}>
                              Apply
                            </IonButton>
                          </div> */}

                      {referralError.show && (
                        <p
                          className="fs-14 ms-1 mt-2"
                          style={
                            referralError.error.includes("Successfully")
                              ? { color: "green" }
                              : { color: "red" }
                          }
                        >
                          {referralError.error}
                        </p>
                      )}
                    </IonGrid>
                  )}

                  <IonList style={{ display: "flex", margin: " 0px 10px" }}>
                    <IonCheckbox
                      slot="start"
                      style={{ marginRight: "12px", width: 25 }}
                      onIonChange={(e: any) => {
                        setPrivacy(e.target.checked);
                      }}
                    ></IonCheckbox>
                    <IonLabel
                      style={{
                        lineHeight: "20px",
                        fontSize: 11,
                        marginBottom: 0,
                      }}
                    >
                      By Signning up, you agree to our{" "}
                      <span
                        onClick={(event) => {
                          event.preventDefault();
                          event.stopPropagation();
                          window.open(
                            "https://jainshadimilan.com/#/terms",
                            "_blank"
                          );
                        }}
                        className="ion-text-wrap text-right color-primary f-13 font-weight-500"
                      >
                        Terms & Condition{" "}
                      </span>
                      and read our{" "}
                      <span
                        onClick={(event) => {
                          event.stopPropagation();
                          event.stopPropagation();
                          window.open(
                            "https://jainshadimilan.com/#/privacy-policy",
                            "_blank"
                          );
                        }}
                        className="ion-text-wrap text-right color-primary f-13 font-weight-500"
                      >
                        Policy
                      </span>
                    </IonLabel>
                  </IonList>
                  {privacy === false && (
                    <p className="errorText   mt-2">
                      Please Read Accept Our Privacy Policy and Terms &
                      Conditions.
                    </p>
                  )}
                  <IonGrid>
                    <IonRow>
                      <IonCol class="btnFill mt-1 ion-margin-bottom">
                        <IonButton type="submit" expand="block">
                          Submit
                        </IonButton>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                  {!referral && (
                    <div className="m-2 displayProp ion-margin-bottom">
                      <IonLabel
                        style={{
                          lineHeight: "20px",
                          fontSize: 12,
                          marginBottom: 0,
                          marginLeft: 3,
                        }}
                      >
                        <b>Do you have Referral Code ?</b>
                      </IonLabel>
                      <div
                        slot="end"
                        onClick={(event) => {
                          event.stopPropagation();
                          setReferral(true);
                        }}
                      >
                        <IonLabel color="primary" style={{ fontSize: 14 }}>
                          <b>
                            <IonIcon
                              icon={addCircleSharp}
                              style={{ verticalAlign: "sub" }}
                            />{" "}
                            Add Now
                          </b>
                        </IonLabel>
                      </div>
                    </div>
                  )}
                </IonList>
              </form>
            </div>
          </div>
        ) : (
          <>
            {/* phone */}
            <IonHeader>
              <IonToolbar color="primary">
                {localStorage.getItem("lastPage") === "/signup-form" &&
                  userList?.length > 0 && (
                    <div
                      onClick={() => {
                        presentAlert({
                          subHeader: "Are you sure want to end process ?",
                          cssClass: "actionAlert",
                          buttons: [
                            {
                              text: "Yes",
                              role: "confirm",
                              handler: async () => {
                                history.replace("/otp");
                                history.push({
                                  pathname: "/otp",
                                  state: {
                                    accessToken: backData?.accessToken,
                                    mobile: backData?.users?.mobile,
                                    id: backData?.users?.id,
                                    newUser: backData?.newUser,
                                  },
                                });
                              },
                            },
                            {
                              text: "no",
                              role: "cancel",
                            },
                          ],
                        });
                      }}
                      slot="start"
                      className="backBtn"
                    >
                      <IonButton type="submit" className="px-1">
                        <IonIcon icon={arrowBackOutline} color="light" />
                      </IonButton>
                    </div>
                  )}
                <IonTitle style={{ textAlign: "center", fontSize: 18 }}>
                  <b>Create Account</b>
                </IonTitle>
              </IonToolbar>
            </IonHeader>
            <IonContent>
              <form onSubmit={handleSubmit(onSubmit)}>
                <IonList class="p-20" style={{ paddingBottom: "70px" }}>
                  <IonGrid>
                    <IonLabel position="stacked" className="fs-16 labelItem">
                      Profile Created For{" "}
                      <span className="fs-12">(प्रोफाइल किसके लिए)</span>
                      <span className="errorText m-0">*</span>
                    </IonLabel>
                    <CustomSelect
                      isSearchable={false}
                      options={profileArray}
                      control={control}
                      required={true}
                      name={"profile"}
                      placeholder={"Select Profile created for"}
                    />
                    {errors.profile && errors.profile.type === "required" && (
                      <p className="errorText ms-0 mt-2">Profile is required</p>
                    )}
                  </IonGrid>
                  <IonGrid>
                    <IonLabel position="stacked" className="fs-16 labelItem">
                      Candidate Name{" "}
                      <span className="fs-12">(प्रत्याशी का नाम)</span>
                      <span className="errorText m-0">*</span>
                    </IonLabel>
                    <IonInput
                      placeholder={"Candidate Full Name"}
                      className="border  Color-Placeholder"
                      {...register("name", { required: true })}
                    ></IonInput>
                  </IonGrid>
                  {errors.name && errors.name.type === "required" && (
                    <p className="errorText ms-1"> Name is required</p>
                  )}
                  <IonGrid>
                    <IonLabel position="stacked" className="fs-16 labelItem">
                      Candidate Age{" "}
                      <span className="fs-12">(प्रत्याशी की उम्र)</span>
                      <span className="errorText m-0">*</span>
                    </IonLabel>
                    <CustomSelect
                      isSearchable={false}
                      options={ageArray}
                      control={control}
                      required={true}
                      name={"age"}
                      placeholder={"Select Age"}
                    />
                    {errors.age && errors.age.type === "required" && (
                      <p className="errorText ms-0 mt-2">Age is required</p>
                    )}
                  </IonGrid>
                  <IonGrid>
                    <IonRow>
                      <IonCol>
                        <IonLabel
                          className="fs-16 labelItem pl-0"
                          position="stacked"
                        >
                          Gender<span className="errorText m-0">*</span>
                        </IonLabel>
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol
                        size="6"
                        style={{ paddingRight: 20 }}
                        onClick={() => setSelectGender("Male")}
                      >
                        <div
                          className="genderImg"
                          style={{
                            border:
                              SelectGender === "Male"
                                ? "1px solid #ee453d"
                                : "",
                          }}
                        >
                          <img loading="lazy" src={vector1} />
                          <p className="dark fs-15 font-weight-500 ion-text-center m-0 Color-Theme">
                            Male
                          </p>
                          <p className="dark fs-12 font-weight-500 ion-text-center m-0 Color-Theme">
                            (युवक)
                          </p>
                        </div>
                      </IonCol>
                      <IonCol
                        size="6"
                        onClick={() => setSelectGender("Female")}
                      >
                        <div
                          className="genderImg"
                          style={{
                            border:
                              SelectGender === "Female"
                                ? "1px solid #ee453d"
                                : "",
                          }}
                        >
                          <img loading="lazy" src={vector2} />
                          <p className="dark fs-15 font-weight-500 ion-text-center m-0 Color-Theme">
                            Female
                          </p>
                          <p className="dark fs-12 font-weight-500 ion-text-center m-0 Color-Theme">
                            (युवती)
                          </p>
                        </div>
                      </IonCol>
                      {SelectGenderError && (
                        <p className="errorText  ms-0 mt-2">
                          Gender is required
                        </p>
                      )}
                    </IonRow>
                  </IonGrid>
                  <IonGrid>
                    <IonRow>
                      <IonCol size="12">
                        <IonLabel
                          position="stacked"
                          className="fs-16 labelItem"
                        >
                          Sect <span className="fs-12">(पंथ)</span>
                          <span className="errorText m-0">*</span>
                        </IonLabel>
                        <CustomSelect
                          options={sectOption}
                          control={control}
                          required={true}
                          name={"sect"}
                          placeholder={"Select Sect"}
                          isSearchable={false}
                        />
                        {errors.sect && errors.sect.type === "required" && (
                          <p className="errorText  ms-0 mt-2">
                            Sector is required
                          </p>
                        )}
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                  <IonGrid>
                    <IonRow>
                      <IonCol size="12">
                        <IonLabel
                          position="stacked"
                          className="fs-16 labelItem"
                        >
                          Marital Status{" "}
                          <span className="fs-12">(वैवाहिक स्थिति)</span>
                          <span className="errorText m-0">*</span>
                        </IonLabel>
                        <CustomSelect
                          options={[
                            { label: "Unmarried", value: "Unmarried" },
                            { label: "Widow/Widower", value: "Widow/Widower" },
                            { label: "Divorced", value: "Divorced" },
                            {
                              label: "Awaiting divorce",
                              value: "Awaiting divorce",
                            },
                          ]}
                          control={control}
                          required={true}
                          name={"marritalStatus"}
                          placeholder={"Select Marital status"}
                          isSearchable={false}
                        />
                        {errors.marritalStatus &&
                          errors.marritalStatus.type === "required" && (
                            <p className="errorText  ms-0 mt-2">
                              Marital Status is required
                            </p>
                          )}
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                  {noOfChild && (
                    <>
                      <IonGrid>
                        <IonLabel
                          position="stacked"
                          className="fs-16 labelItem"
                        >
                          No. of childrens
                          <span className="errorText m-0">*</span>
                        </IonLabel>
                        <CustomSelect
                          options={childrenArray}
                          control={control}
                          required={false}
                          name={"first_formNoOfChildren"}
                          placeholder={"Select No. of childrens"}
                          isSearchable={false}
                        />
                      </IonGrid>
                      {errors.first_formNoOfChildren &&
                        errors.first_formNoOfChildren.type === "required" && (
                          <p className="errorText ms-1">
                            {" "}
                            No. of childrens is required
                          </p>
                        )}
                    </>
                  )}
                  {noOfChild && childLivingStatus && (
                    <IonRow>
                      <IonCol size="12">
                        <IonLabel
                          position="stacked"
                          className="fs-16 labelItem"
                        >
                          Living Status<span className="errorText m-0">*</span>
                        </IonLabel>
                        <CustomSelect
                          options={[
                            { label: "Live with me", value: "Live with me" },
                            {
                              label: "Not live with me",
                              value: "Not live with me",
                            },
                          ]}
                          control={control}
                          required={false}
                          name={"childrenLivingStatus"}
                          placeholder={"Select Liiving status"}
                          isSearchable={false}
                        />
                        {errors.childrenLivingStatus &&
                          errors.childrenLivingStatus.type === "required" && (
                            <p className="errorText  ms-0 mt-2">
                              Living Status is required
                            </p>
                          )}
                      </IonCol>
                    </IonRow>
                  )}
                  <IonGrid>
                    <IonRow>
                      <IonCol size="12">
                        <IonLabel
                          position="stacked"
                          className="fs-16 labelItem"
                        >
                          State <span className="fs-12">(राज्य)</span>
                          <span className="errorText m-0">*</span>
                        </IonLabel>
                        <CustomSelect
                          options={stateList}
                          control={control}
                          required={true}
                          name={"state"}
                          placeholder={"Select State"}
                          isSearchable={true}
                        />
                        {errors?.state && (
                          <p className="errorText  ms-0 mt-2">
                            State is required
                          </p>
                        )}
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                  {referral && (
                    <IonGrid>
                      <div className="position-relative">
                        <div style={{ width: "100%", marginBottom: "8px" }}>
                          <IonLabel
                            style={{
                              lineHeight: "20px",
                              fontSize: 11,
                              marginBottom: 0,
                              marginLeft: 3,
                              color: "#000",
                            }}
                          >
                            <b> Referral Code </b>
                          </IonLabel>
                          <IonItem>
                            <IonInput
                              placeholder={"Enter your Referral Code here..."}
                              className="referral_input me-3 outlined-input"
                              {...register("referral_code")}
                            ></IonInput>
                            <IonButton
                              slot="end"
                              onClick={async (event) => {
                                event.stopPropagation();
                                const watchReferral = watch("referral_code");
                                var res = await verifyReferral({
                                  referralId: watchReferral,
                                });
                                if (res?.data?.status) {
                                  setReferralCode(res?.data?.data?.id);
                                  setReferralError({
                                    show: true,
                                    error: `Referral Code added Successfully of  user  ${res?.data?.data?.nameEnglish}.`,
                                  });
                                } else {
                                  setReferralError({
                                    show: true,
                                    error: "Referral Code not Matched.",
                                  });
                                }
                              }}
                              style={{ marginBottom: "14px" }}
                            >
                              Apply
                            </IonButton>
                          </IonItem>
                        </div>
                        {/* <div
                            slot="end"
                            className="backBtn"
                            onClick={async (event) => {
                              event.stopPropagation();
                              const watchReferral = watch("referral_code");
                              var res = await verifyReferral({
                                referralId: watchReferral,
                              });
                              if (res?.data?.status) {
                                setReferralCode(res?.data?.data?.id);
                                setReferralError({
                                  show: true,
                                  error: `Referral Code added Successfully of  user  ${res?.data?.data?.nameEnglish}.`,
                                });
                              } else {
                                setReferralError({
                                  show: true,
                                  error: "Referral Code not Matched.",
                                });
                              }
                            }}
                          >
                            <IonButton style={{ marginBottom: "14px" }}>
                              Apply
                            </IonButton>
                          </div> */}
                        {/* <IonIcon icon={closeCircleSharp}/> */}
                        <IonIcon
                          onClick={(event) => {
                            event.stopPropagation();
                            setReferral(false);
                          }}
                          className="fs-20"
                          icon={closeCircleSharp}
                          style={{ position: "absolute", top: -4, right: 0 }}
                        />
                      </div>

                      {referralError.show && (
                        <p
                          className="fs-14 ms-1 mt-2"
                          style={
                            referralError.error.includes("Successfully")
                              ? { color: "green" }
                              : { color: "red" }
                          }
                        >
                          {referralError.error}
                        </p>
                      )}
                    </IonGrid>
                  )}

                  <IonList
                    style={{
                      display: "flex",
                      margin: " 0px 10px",
                      alignItems: "center",
                    }}
                  >
                    <IonCheckbox
                      slot="start"
                      style={{ marginRight: "15px", width: 24, height: 24 }}
                      onIonChange={(e: any) => {
                        setPrivacy(e.target.checked);
                      }}
                    ></IonCheckbox>
                    <IonLabel
                      style={{
                        lineHeight: "20px",
                        fontSize: 12,
                        marginBottom: 0,
                      }}
                      className="Color-Theme"
                    >
                      I have read & agree the
                      <span
                        onClick={(event) => {
                          event.preventDefault();
                          event.stopPropagation();
                          window.open(
                            "https://jainshadimilan.com/#/terms",
                            "_blank"
                          );
                        }}
                        className="ion-text-wrap text-right color-primary f-13 font-weight-500"
                      >
                        Terms
                      </span>{" "}
                      &
                      <span
                        onClick={(event) => {
                          event.preventDefault();
                          event.stopPropagation();
                          window.open(
                            "https://jainshadimilan.com/#/terms",
                            "_blank"
                          );
                        }}
                        className="ion-text-wrap text-right color-primary f-13 font-weight-500"
                      >
                        {" "}
                        Conditions
                      </span>
                      {/* and read our <span onClick={(event) => {
                                            event.stopPropagation();
                                            event.stopPropagation();
                                            window.open('https://jainshadimilan.com/privacy-policy', '_blank');
                                        }} className='ion-text-wrap text-right color-primary f-13 font-weight-500'>Policy</span>  */}
                    </IonLabel>
                  </IonList>
                  {/* {privacy === false && <p className='errorText   mt-2'>Please Read Accept Our Privacy Policy and Terms & Conditions.</p>} */}
                  <IonGrid>
                    <IonRow>
                      <IonCol class="btnFill mt-0 px-0 ">
                        <IonButton type="submit" expand="block">
                          Submit
                        </IonButton>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                  {!referral && (
                    <div className="m-2 displayProp ion-margin-bottom">
                      <IonLabel
                        className="Color-Theme"
                        style={{
                          lineHeight: "20px",
                          fontSize: 12,
                          marginBottom: 0,
                          marginLeft: 3,
                        }}
                      >
                        <b>Do you have Referral Code ?</b>
                      </IonLabel>
                      <div
                        slot="end"
                        onClick={(event) => {
                          event.stopPropagation();
                          setReferral(true);
                        }}
                      >
                        <IonLabel color="primary" style={{ fontSize: 14 }}>
                          <b>
                            <IonIcon
                              icon={addCircleSharp}
                              style={{ verticalAlign: "sub" }}
                            />{" "}
                            Add Now
                          </b>
                        </IonLabel>
                      </div>
                    </div>
                  )}
                </IonList>
              </form>
            </IonContent>
          </>
        )}
      </div>
    </IonPage>
  );
};

export default Signup;
