import {
  IonAvatar,
  IonImg,
  IonPage,
  IonSkeletonText,
  IonThumbnail,
  useIonAlert,
  useIonToast,
  useIonViewDidLeave,
  useIonRouter,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { IonItem, IonLabel } from "@ionic/react";
import { IonIcon } from "@ionic/react";
import { IonCol, IonRow } from "@ionic/react";
import { IonList, IonButton } from "@ionic/react";
import appContext from "../../context/AppContext";
import constant from "../../constant";
import { Link, useLocation } from "react-router-dom";
import { chevronForwardOutline } from "ionicons/icons";
import { useHistory } from "react-router-dom";
import {
  authorizeMe,
  generateToken,
  profileList,
  updateOtpToMA,
} from "../../service";
import { PushNotifications, Token } from "@capacitor/push-notifications";
import Utils from "../utils";
import logo from "../../assets/images/logo.png";

const Userlist: React.FC = () => {
  const location = useLocation();
  const AppContext = useContext(appContext);
  const history = useHistory();
  const { state }: any = useLocation();
  const [userList, setUserList] = useState([]);
  const [DeviceToken, setDeviceToken] = useState("");
  const [present] = useIonToast();
  const [presentAlert] = useIonAlert();

  const ionRouter: any = useIonRouter();

  document.addEventListener("ionBackButton", (ev: any) => {
    ev.detail.register(-1, () => {
      if (ionRouter.routeInfo?.pathname === "/user-list") {
        localStorage.setItem("lastPage", "/signin");
        AppContext.setIsLoggedIn(false);
        localStorage.clear();
        AppContext.setIsLoggedIn(false);
        window.location.assign("/");
        history.replace("/signin");
      }
    });
  });

  useEffect(() => {
    PushNotifications.register();
    PushNotifications.addListener("registration", (token: Token) => {
      setDeviceToken(token.value);
    });
  }, [DeviceToken]);

  var res1: any = localStorage.getItem("responseData");
  var res = JSON.parse(res1);
  var newMobile = localStorage.getItem("mobile");

  useEffect(() => {
    localStorage.setItem("lastPage", window.location.pathname);
    document.addEventListener("ionBackButton", (ev: any) => {
      localStorage.setItem("lastPage", "/user-list");
      if (state) {
        history?.replace({ pathname: "/user-list", state: state });
      } else {
        history?.replace({
          pathname: "/user-list",
          state: { accessToken: res?.accessToken, mobile: newMobile },
        });
      }
    });
    return () => {
      document.removeEventListener("ionBackButton", () => {});
    };
  }, [window.location.pathname]);

  useEffect(() => {
    const getUserlist = async () => {
      if (state) {
        var response = await profileList(
          { mobile: state?.mobile },
          state?.accessToken
        );
        setUserList(response?.data?.data);
      } else {
        var response = await profileList(
          { mobile: newMobile },
          res?.accessToken
        );
        setUserList(response?.data?.data);
      }
    };
    if (state?.accessToken || res?.accessToken) {
      getUserlist();
    }
  }, [state?.mobile, state?.accessToken]);

  const loginNewUser = async (selectedUser: any) => {
    let p = selectedUser?.profiles?.find((i: any) => {
      return i?.type === "profile" && i?.order_number === 10;
    });
    if (!p)
      p = selectedUser?.profiles?.find((i: any) => {
        return i?.type === "profile";
      });
    let url = p
      ? `${constant.ImageS3URl}/${p?.folder}/${p?.name}`
      : Utils.getDefaultUserImg(selectedUser?.first_formGenderEnglish);
    presentAlert({
      header: `Login with ${selectedUser?.nameEnglish}?`,
      message: `
            <IonItem>
                <IonThumbnail className='ion-margin-end'>
                <img  alt="Silhouette of mountains" src=${url} >
                </IonThumbnail>
            </IonItem>
            `,
      cssClass: "actionAlert",
      buttons: [
        {
          text: "Yes",
          role: "confirm",
          handler: async () => {
            AppContext.setLoading(true);
            var response = await generateToken(
              {
                id: selectedUser.id,
                app_id: DeviceToken,
                login_device_detail: localStorage.getItem("DeviceData"),
              },
              state?.accessToken
            );
            await updateOtpToMA(selectedUser.id);
            if (response.data.status) {
              authorizeMe(response?.data?.data?.accessToken);
              AppContext.setUser({
                ...response?.data?.data?.user,
                token: response?.data?.data?.accessToken,
              });
              AppContext?.setIsLoggedIn(true);
              AppContext.setLoading(false);
              // history.replace("/");
              window.location.assign("/");
            } else {
              AppContext.setLoading(false);
              present({
                message: response?.data?.errors,
                duration: 1500,
                position: "top",
                color: "primary",
                cssClass: "alertToast",
              });
            }
          },
        },
        {
          text: "Change User",
          role: "cancel",
        },
      ],
    });
  };
  const mediaQuery = window.matchMedia("(min-width: 576px)");

  return (
    <IonPage className={mediaQuery.matches ? "test-background-image" : "bg"}>
      <div className="auth-content">
        <div>
          <img
            loading="lazy"
            className="web-logo"
            src={logo}
            alt="logo-image"
          ></img>
          <div style={{ marginTop: "20px" }} className="web-body-text ">
            <IonLabel>
              <h1 style={{ width: 500 }}>
                <b>Best Matrimony Site Used By Millions of Jainism Members .</b>
              </h1>
            </IonLabel>
          </div>
        </div>

        <div className="authbottomCover auth-web-bottom">
          <IonRow class="ion-justify-content-center bottom-text">
            <IonCol size="10" class="mtb-10">
              <h4 className="fs-16 dark">
                <b>
                  Select the Account which you wants to login or{" "}
                  <span>
                    {" "}
                    <br />
                    Click here to{" "}
                    <span
                      style={{ color: "red", cursor: "pointer" }}
                      onClick={() => {
                        localStorage.setItem("lastPage", "/signin");
                        AppContext.setIsLoggedIn(false);
                        localStorage.clear();
                        AppContext.setIsLoggedIn(false);
                        window.location.assign("/");
                        history.replace("/signin");
                      }}
                    >
                      Logout
                    </span>
                  </span>
                </b>
              </h4>
            </IonCol>
          </IonRow>

          {userList?.length < 1 ? (
            <IonList style={{ maxHeight: "50vh", overflow: "scroll" }}>
              {[...Array(3)].map((user) => (
                <IonItem>
                  <IonThumbnail className="ion-margin-end">
                    <IonSkeletonText animated></IonSkeletonText>
                  </IonThumbnail>
                  <IonLabel>
                    <h3>
                      <IonSkeletonText
                        animated={true}
                        style={{ width: "50%" }}
                      ></IonSkeletonText>
                    </h3>
                    <p>
                      <IonSkeletonText
                        animated={true}
                        style={{ width: "40%" }}
                      ></IonSkeletonText>
                    </p>
                  </IonLabel>
                  <IonAvatar slot="end">
                    <IonSkeletonText animated></IonSkeletonText>
                  </IonAvatar>
                </IonItem>
              ))}
            </IonList>
          ) : (
            <IonList style={{ maxHeight: "50vh", overflow: "scroll" }}>
              {userList?.map((user: any) => {
                let p = user?.profiles?.find((i: any) => {
                  return i?.type === "profile" && i?.order_number === 10;
                });
                if (!p)
                  p = user?.profiles?.find((i: any) => {
                    return i?.type === "profile";
                  });
                return (
                  <IonItem
                    onClick={() => {
                      loginNewUser(user);
                    }}
                  >
                    <IonThumbnail className="ion-margin-end">
                      <img
                        alt="Silhouette of mountains"
                        src={
                          p
                            ? `${constant.ImageS3URl}/${p?.folder}/${p?.name}`
                            : Utils.getDefaultUserImg(
                                user?.first_formGenderEnglish
                              )
                        }
                      />
                    </IonThumbnail>
                    <IonLabel>
                      <h3 className="userName">
                        <b>{user?.nameEnglish}</b>
                      </h3>
                      <p>{user?.first_formProfileEnglish}</p>
                    </IonLabel>
                    <IonIcon
                      color="primary"
                      size="large"
                      className="fs-18"
                      icon={chevronForwardOutline}
                    />
                  </IonItem>
                );
              })}
            </IonList>
          )}

          {AppContext?.userStatus?.form === constant.SignUp && (
            <IonRow
              class="ion-justify-content-center bottom-text"
              style={{ margin: 10 }}
            >
              <IonCol size="12" className="btnFill">
                <IonButton
                  expand="block"
                  onClick={() => {
                    history.push({
                      pathname: constant.routes.signupform,
                      state: state,
                    });
                  }}
                  className="fs-18"
                >
                  Add new profile
                </IonButton>
              </IonCol>
            </IonRow>
          )}
          {mediaQuery.matches && (
            <div style={{ textAlign: "center" }}>
              <Link to="/signin" style={{ color: "#EF423B", fontWeight: 600 }}>
                Back to Sign In
              </Link>
            </div>
          )}
        </div>
      </div>
    </IonPage>
  );
};
export default Userlist;
