import vector2 from "../assets/images/Vector(2).png";
import vector1 from "../assets/images/Vector(1).png";

const getDefaultUserUrl = (user: String) => {
  switch (user) {
    case "Male":
      return `url(${vector2})`;

    case "Female":
      return `url(${vector1})`;

    default:
      return "https://ionicframework.com/docs/img/demos/thumbnail.svg";
  }
};

const getDefaultUserImg = (user: String) => {
  switch (user) {
    case "Male":
      return `${vector1}`;

    case "Female":
      return `${vector2}`;

    default:
      return "https://ionicframework.com/docs/img/demos/thumbnail.svg";
  }
};

const formCalculation = (user: any) => {
  var fistFormFillValue = 0;
  if (
    user.second_formCountryEnglish &&
    user.second_formStateEnglish &&
    user.second_formCityEnglish &&
    user.second_formAddressEnglish &&
    ((user.fifth_formResidentalStatusEnglish &&
      user.second_formLivingCountryEnglish &&
      user.second_formLivingStateEnglish &&
      user.second_formLivingCityEnglish &&
      user.second_formLivingAddressEnglish) ||
      user?.second_formSameAddress)
  )
    fistFormFillValue = 10;

  var secondFormFillValue = 0;
  if (
    user.fourth_formEducationEnglish &&
    user.fourth_formDescribeEducationEnglish &&
    user.fourth_formAfterMarriageEnglish &&
    user.fourth_formHobbiesInterest &&
    user.fourth_formHobbiesInterestWriteEnglish
  )
    secondFormFillValue = 10;

  var thirdFormFillValue = 0;
  if (
    user.fifth_formFamilyMember &&
    user.fifth_formFamilyTypeEnglish &&
    // user.fifth_formFatherContactNumberMobile &&
    user.fifth_formFatherEmployedInEnglish &&
    user.fifth_formFatherIncomeEnglish &&
    user.second_formMotherTongueEnglish &&
    user.fifth_formFatherNameEnglish
  )
    thirdFormFillValue = 10;

  var fourthFormFillValue = 0;
  if (
    user.dob &&
    user.third_formBirthdetailsTime &&
    user.third_formBirthdetailsDescriptionEnglish
  )
    fourthFormFillValue = 10;

  var signUpFormFillValue = 0;
  if (
    user.nameEnglish &&
    user.first_formGenderEnglish &&
    user.third_formSectEnglish &&
    user.first_formMaritalStatusEnglish &&
    user.first_formProfileEnglish &&
    user.second_formStateEnglish &&
    user._age
  )
    signUpFormFillValue = 10;

  var profilePictureScore = 0;
  if (
    user?.profiles?.find((i: any) => {
      return i?.type === "profile";
    })
  )
    profilePictureScore = 10;

  var bioDataScore = 0;
  if (
    user?.profiles?.find((i: any) => {
      return i?.type === "birth";
    })
  )
    bioDataScore = 10;

  var idProofScore = 0;
  if (
    user?.profiles?.find((i: any) => {
      return i?.type === "id";
    })
  )
    idProofScore = 9;

  var golive = 0;
  if (user.second_formMobile) golive = 10;

  var sixthFormValue = 0;
  if (
    user?.partner_preferenceCandidateIncomeEnglish?.length > 0 ||
    user?.partner_preferenceEmployedInEnglish?.length > 0 ||
    user?.partner_preferenceManglikStatusEnglish?.length > 0 ||
    user?.partner_preferenceMaritalStatusEnglish?.length > 0 ||
    user?.partner_preferenceSectEnglish?.length > 0 ||
    user?.partner_preferenceStateEnglish?.length > 0
  )
    sixthFormValue = 7;

  var fifthFormValue = 0;

  if (
    user?.third_formSectEnglish &&
    user?.third_formSubSectEnglish &&
    user?.third_formGotraEnglish
  )
    fifthFormValue = 4;

  var profileScore =
    fourthFormFillValue +
    fistFormFillValue +
    secondFormFillValue +
    thirdFormFillValue +
    profilePictureScore +
    signUpFormFillValue +
    golive +
    bioDataScore +
    idProofScore +
    fifthFormValue +
    sixthFormValue;

  // fourthFormFillValue 10 +
  // fistFormFillValue 10 +
  // secondFormFillValue 10 +
  // thirdFormFillValue 10 +
  // profilePictureScore 10 +
  // signUpFormFillValue 10  +
  // golive 10 +
  // bioDataScore 10 +
  // idProofScore 9 +
  // fifthFormValue 4 + sixthFormValue 7;

  return {
    profileScore: profileScore,
    signUpFormFillValue: signUpFormFillValue,
    profilePictureScore: profilePictureScore,
    bioDataScore: bioDataScore,
    idProofScore: idProofScore,
    golive: golive,
    fistFormFillValue: fistFormFillValue,
    secondFormFillValue: secondFormFillValue,
    thirdFormFillValue: thirdFormFillValue,
    fourthFormFillValue: fourthFormFillValue,
    fifthForm: fifthFormValue,
    sixthForm: sixthFormValue,
  };
};

const utills = {
  getDefaultUserUrl,
  getDefaultUserImg,
  formCalculation,
};
export default utills;
