import { IonButton, IonGrid, IonImg, IonLabel, IonPage } from "@ionic/react";
import { useCallback, useContext, useEffect, useState } from "react";
import { IonCol, IonRow } from "@ionic/react";
import appContext from "../../context/AppContext";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import OtpInput from "react-otp-input";
import {
  authorizeMe,
  generateToken,
  getProfileStats,
  SendOtp,
  verifyOtp,
} from "../../service";
import { useIonToast } from "@ionic/react";
import { alertCircleOutline } from "ionicons/icons";
import constant from "../../constant";
import { PushNotifications, Token } from "@capacitor/push-notifications";
import logo from "../../assets/images/logo.png";
import { AxiosError } from "axios";

const timerConst = 40;

const Otp = () => {
  const [present] = useIonToast();
  const history = useHistory();
  const { state }: any = useLocation();
  const AppContext = useContext(appContext);
  const [code, setCode] = useState(state?.otp ? state?.otp : "");
  const [otpError, setOtpError] = useState({
    show: false,
    message: "OTP is required",
  });
  const [seconds, setSeconds] = useState(timerConst);
  const [DeviceToken, setDeviceToken] = useState("");

  let initialValues = { otp: "" };

  const { handleSubmit } = useForm({
    defaultValues: initialValues,
  });

  useEffect(() => {
    PushNotifications.register();
    PushNotifications.addListener("registration", (token: Token) => {
      setDeviceToken(token.value);
    });
  }, [DeviceToken]);

  useEffect(() => {
    setSeconds(timerConst);
    const otpRestoreKey = constant.otpRestore;
    const storedOtp = localStorage.getItem(otpRestoreKey);
    const mobileNumber = state?.mobile;

    if (!storedOtp || (storedOtp === "undefined" && mobileNumber)) {
      localStorage.setItem(otpRestoreKey, mobileNumber);
    }
  }, [state?.mobile]);

  const onSubmit = useCallback(async () => {
    console.log("onSubmit called");

    AppContext.setLoading(true);
    if (!code) {
      setOtpError({ show: true, message: "OTP is required" });
      return;
    }
    if (code.length < 4) {
      setOtpError({ show: true, message: "OTP must be 4 digits." });
      return;
    }
    setOtpError({ show: false, message: "" });

    const timeoutDuration = 3000;
    let timeout = setTimeout(() => {
      if (!AppContext.loading) {
        AppContext.setLoading(false);
        present({
          message: "Your connection is weak. Please try again.",
          duration: 2500,
          position: "top",
          color: "primary",
          icon: alertCircleOutline,
          cssClass: "alertToast",
        });
      }
    }, timeoutDuration);

    try {
      const response = await verifyOtp({ mobile: state?.mobile, otp: code });
      clearTimeout(timeout);

      if (response.data.status) {
        localStorage.removeItem(constant.otpRestore);
        authorizeMe(response?.data?.data?.accessToken);

        const res = await getProfileStats();
        localStorage.setItem(
          "responseData",
          JSON.stringify(response.data.data)
        );
        localStorage.setItem("mobile", state?.mobile);
        if (response?.data?.data?.newUser) {
          history.replace({
            pathname: constant.routes.signupform,
            state: {
              accessToken: response.data.data.accessToken,
              id: response.data.data.users.id,
              mobile: state?.mobile,
              newUser: response.data.data.newUser,
            },
          });
        } else {
          if (
            AppContext?.userStatus?.form === constant.SignIn &&
            res?.data?.data?.totalAccount === 1
          ) {
            AppContext.setLoading(true);
            const resp = await generateToken(
              {
                id: response?.data?.data?.users.id,
                app_id: DeviceToken,
                login_device_detail: localStorage.getItem("DeviceData"),
              },
              response?.data?.data?.accessToken
            );

            if (resp?.data?.status) {
              AppContext.setUser({
                ...resp?.data?.data?.user,
                token: resp?.data?.data?.accessToken,
              });
              AppContext?.setIsLoggedIn(true);
              AppContext.setLoading(false);
              // history.push("/");
              window.location.assign("/");
            } else {
              AppContext.setLoading(false);
              present({
                message: response?.data?.errors,
                duration: 1500,
                position: "top",
                color: "primary",
                cssClass: "alertToast",
              });
            }
          } else {
            AppContext.setLoading(false);
            history.replace({
              pathname: constant.routes.userlist,
              state: {
                accessToken: response.data.data.accessToken,
                id: response.data.data.users.id,
                mobile: state?.mobile,
                newUser: response.data.data.newUser,
              },
            });
          }
        }
      } else {
        present({
          message: "OTP Incorrect or Poor Network Connection",
          duration: 2500,
          position: "top",
          color: "primary",
          icon: alertCircleOutline,
          cssClass: "alertToast",
        });
      }
    } catch (error) {
      present({
        message: "An error occurred. Please try again.",
        duration: 2500,
        position: "top",
        color: "primary",
        icon: alertCircleOutline,
        cssClass: "alertToast",
      });
    } finally {
      AppContext.setLoading(false);
    }
  }, [code, AppContext, DeviceToken, history, present, state?.mobile]);

  useEffect(() => {
    if (code.length === 4) onSubmit();
  }, [code, onSubmit]);

  const handleChange = (code: any) => setCode(code);
  const mediaQuery = window.matchMedia("(min-width: 576px)");
  return (
    <IonPage>
      <div
        className={
          mediaQuery.matches ? "test-background-image" : "bg UserListBody"
        }
      >
        <div className={mediaQuery.matches ? "auth-content" : ""}>
          <div>
            <img
              loading="lazy"
              className="web-logo"
              src={logo}
              alt="logo-image"
            ></img>
            <div style={{ marginTop: "20px" }} className="web-body-text ">
              <IonLabel>
                <h1 style={{ width: 500 }}>
                  <b>
                    Best Matrimony Site Used By Millions of Jainism Members .
                  </b>
                </h1>
              </IonLabel>
            </div>
          </div>
          <div className="authbottomCover auth-web-bottom">
            <form onSubmit={handleSubmit(onSubmit)}>
              <IonRow class="ion-justify-content-center bottom-text">
                <IonCol>
                  <div className="otp-lock"></div>
                </IonCol>
                <IonCol size="11">
                  <h6 style={{ lineHeight: "24px" }}>
                    Enter the OTP (One Time Password) sent on{" "}
                    <span style={{ whiteSpace: "nowrap" }}>
                      {" "}
                      +91-{state?.mobile}
                    </span>
                  </h6>
                  <Link
                    to={constant.routes.signin}
                    onClick={() => {
                      localStorage.removeItem(constant.otpSent);
                      // localStorage.removeItem(constant.otpRestore);
                    }}
                  >
                    <p
                      style={{
                        fontSize: 12,
                        color: "#fff",
                        backgroundColor: "#f7a09d",
                        padding: "3px",
                        margin: "0px 44px",
                        borderRadius: "7px",
                      }}
                    >
                      Click here to change mobile number
                    </p>
                  </Link>
                </IonCol>
              </IonRow>
              <IonRow className="otpSection">
                <OtpInput
                  value={code}
                  onChange={handleChange}
                  numInputs={4}
                  separator={<span style={{ width: "20px" }}></span>}
                  isInputNum={true}
                  shouldAutoFocus={true}
                  inputStyle={{
                    border: "1px solid transparent",
                    width: "70px",
                    height: "54px",
                    fontSize: "32px",
                    color: "var(--ion-color-dark)",
                    fontWeight: "500",
                    caretColor: "blue",
                    borderBottom: "1px solid #CFD3DB",
                    borderRadius: "0px",
                    background: "var(--ion-color-light)",
                  }}
                  focusStyle={{
                    borderBottom: "1px solid #EF423B",
                    borderRadius: "0px",
                    outline: "none",
                  }}
                />
              </IonRow>
              {otpError.show && <p className="errorText">{otpError.message}</p>}
              <Timer
                setSeconds={setSeconds}
                seconds={seconds}
                setCode={setCode}
              />
              <IonGrid>
                <IonRow>
                  <IonCol class="btnFill mt-0 mb-1 px-0 ">
                    <IonButton
                      type="submit"
                      expand="block"
                      disabled={code?.length !== 4}
                    >
                      Submit
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </form>
          </div>
        </div>
      </div>
    </IonPage>
  );
};

const Timer = ({ setSeconds, seconds, setCode }: any) => {
  const [otpAgain, setOtpAgain] = useState(false);
  const { state }: any = useLocation();
  const [present] = useIonToast();

  useEffect(() => {
    if (seconds === 0) {
      setCode("");
    }
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [seconds, otpAgain, setSeconds]);

  const reSendOtp = async () => {
    try {
      var obj = { mobile: state?.mobile };
      let response = await SendOtp(obj);
      if (response?.data?.status) {
        setSeconds(timerConst);
        setOtpAgain(!otpAgain);
        present({
          message: "OTP send Successfully",
          duration: 1500,
          position: "top",
          color: "success",
          cssClass: "alertToast",
        });
      }
    } catch (error) {
      const axiosError = error as AxiosError;
      present({
        message: (axiosError?.response?.data as any)?.message,
        duration: 1500,
        position: "top",
        color: "danger",
      });
    }
  };

  return (
    <IonRow class="ion-justify-content-center bottom-text">
      <IonCol size="11" class="mtb-20">
        <div className="countdown-text">
          {seconds > 0 ? (
            <p>
              Time Remaining:{" "}
              <b style={{ color: "#EF423B" }}>
                00:{seconds < 10 ? `0${seconds}` : seconds}
              </b>
            </p>
          ) : (
            <h6 style={{ lineHeight: "24px" }}>
              Don’t receive the OTP ?{" "}
              <span
                className="ion-text-wrap text-right color-primary fs-16 font-weight-500"
                onClick={() => {
                  reSendOtp();
                }}
              >
                RESEND
              </span>
            </h6>
          )}
        </div>
      </IonCol>
    </IonRow>
  );
};
export default Otp;
