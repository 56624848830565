import {
  IonIcon,
  IonImg,
  IonPage,
  IonSlide,
  IonSlides,
  useIonToast,
  useIonRouter,
  IonAlert,
} from "@ionic/react";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { IonItem, IonLabel } from "@ionic/react";
import { IonTabBar, IonTabButton } from "@ionic/react";
import { IonCol, IonGrid, IonRow } from "@ionic/react";
import { IonInput, IonList, IonButton } from "@ionic/react";
import appContext from "../../context/AppContext";
import constant from "../../constant";
import { App } from "@capacitor/app";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  authorizeMe,
  generateToken,
  getProfileStats,
  login,
  SendOtp,
} from "../../service";
import { eyeOffSharp, eyeSharp } from "ionicons/icons";
import Img1 from "../../assets/images/1.jpg";
import Img3 from "../../assets/images/2.jpeg";
import Img5 from "../../assets/images/4.jpeg";
import Img2 from "../../assets/images/22.jpg";
import Img4 from "../../assets/images/44.jpg";
import Img6 from "../../assets/images/66.jpg";
import { PushNotifications, Token } from "@capacitor/push-notifications";
import logo from "../../assets/images/logo.png";
import { Device } from "@capacitor/device";
import { AxiosError } from "axios";
import { App as CapacitorApp } from "@capacitor/app";

const Auth: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const historyObj = useMemo(() => {
    return history;
  }, [history]);
  const [DeviceToken, setDeviceToken] = useState("");
  const AppContext = useContext(appContext);
  const [showExitAlert, setShowExitAlert] = React.useState(false);
  useEffect(() => {
    PushNotifications.register();
    PushNotifications.addListener("registration", (token: Token) => {
      setDeviceToken(token.value);
      localStorage.setItem("app_Token", token.value);
    });
    getDeviceInfo();
  }, [DeviceToken]);

  const getDeviceInfo = async () => {
    try {
      if (Device) {
        const deviceInfo: any = await Device.getInfo();
        localStorage.setItem(
          "DeviceData",
          `${deviceInfo?.model.replace(
            /\s/g,
            ""
          )}-${deviceInfo?.operatingSystem.replace(
            /\s/g,
            ""
          )}-${deviceInfo?.osVersion.replace(
            /\s/g,
            ""
          )}-${deviceInfo?.platform.replace(/\s/g, "")}`
        );
      } else {
        console.error("Device plugin is not available.");
      }
    } catch (error) {
      console.error("Error getting device info:", error);
    }
  };

  const test = async (res: any) => {
    var resp = await generateToken(
      {
        id: res?.users?.id,
        app_id: DeviceToken,
        login_device_detail: localStorage.getItem("DeviceData"),
      },
      res?.accessToken
    );
    if (resp?.data?.status) {
      AppContext.setUser({
        ...resp?.data?.data?.user,
        token: resp?.data?.data?.accessToken,
      });
      AppContext?.setIsLoggedIn(true);
      AppContext.setLoading(false);
      historyObj.replace("/");
      window.location.assign("/");
    } else {
      AppContext.setLoading(false);
      present({
        message: res?.data?.errors,
        duration: 1500,
        position: "top",
        color: "primary",
        cssClass: "alertToast",
      });
    }
  };

  useEffect(() => {
    var path = localStorage.getItem("lastPage");
    var res1: any = localStorage.getItem("responseData");
    var res = JSON.parse(res1);

    var mobile = localStorage.getItem("mobile");
    switch (path) {
      case "/user-list":
        if (res?.newUser) {
          historyObj?.replace({
            pathname: constant.routes.signupform,
            state: {
              accessToken: res?.accessToken,
              id: res?.users.id,
              mobile: mobile,
              newUser: res?.newUser,
            },
          });
        } else {
          if (
            AppContext?.userStatus?.form === constant.SignIn &&
            res?.data?.data?.totalAccount === 1
          ) {
            AppContext.setLoading(true);
            test(res);
          } else {
            history?.replace({
              pathname: constant.routes.userlist,
              state: {
                accessToken: res?.accessToken,
                id: res?.users.id,
                mobile: mobile,
                newUser: res?.newUser,
              },
            });
          }
        }
        break;

      case "/signup-form":
        historyObj?.replace({
          pathname: constant.routes.signupform,
          state: {
            accessToken: res?.accessToken,
            id: res?.users.id,
            mobile: mobile,
            newUser: res?.newUser,
          },
        });
        break;

      default:
        break;
    }
  }, []);

  useEffect(() => {
    if (
      localStorage.getItem(constant.otpSent) &&
      localStorage.getItem(constant.otpSent) !== "undefined" &&
      localStorage.getItem(constant.otpSent) !== "null"
    ) {
      historyObj?.push({
        pathname: constant.routes.otp,
        state: { mobile: localStorage.getItem(constant.otpRestore) },
      });
    }
  }, [historyObj]);

  useEffect(() => {
    PushNotifications.register();
    PushNotifications.addListener("registration", (token: Token) => {
      setDeviceToken(token.value);
    });
  }, [DeviceToken]);

  const [showPassword, setShowPassword] = useState<any>(false);
  const [present] = useIonToast();

  let initialValues = {
    mobile: "",
    password: "",
  };

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: initialValues,
    mode: "onBlur",
    reValidateMode: "onChange",
  });

  const ionRouter = useIonRouter();
  document.addEventListener("ionBackButton", (ev: any) => {
    ev.detail.register(-1, () => {
      if (!ionRouter.canGoBack()) {
        setShowExitAlert(true);
      }
      if (history.location.pathname === "/") {
        setShowExitAlert(true);
      }
    });
  });

  useEffect(() => {
    setValue(
      "mobile",
      !localStorage.getItem(constant.otpRestore) ||
        localStorage.getItem(constant.otpRestore) !== "undefined"
        ? localStorage.getItem(constant.otpRestore) || ""
        : ""
    );
  }, []);

  const onSubmit = async (data: any) => {
    AppContext.setLoading(true);
    try {
      var obj: any = {};
      if (
        AppContext?.userStatus?.form === constant.SignIn &&
        AppContext?.userStatus?.isPassword
      ) {
        obj = {
          mobile: data.mobile || localStorage.getItem(constant.otpRestore),
          password: data.password,
          login_device_detail: localStorage.getItem("DeviceData"),
        };
        var response = await login(obj);
        if (response?.data?.status) {
          // history.push({ pathname: constant.routes.userlist, state: { accessToken: res?.data?.data?.accessToken, id: res?.data?.data?.users?.id, mobile: data?.mobile } });
          authorizeMe(response?.data?.data?.accessToken);
          let res = await getProfileStats();
          if (response?.data?.data?.newUser) {
            history.push({
              pathname: constant.routes.signupform,
              state: {
                accessToken: response.data.data.accessToken,
                id: response.data.data.users.id,
                mobile: data.mobile,
                newUser: response.data.data.newUser,
              },
            });
          } else {
            if (
              AppContext?.userStatus?.form === constant.SignIn &&
              res?.data?.data?.totalAccount === 1
            ) {
              var resp = await generateToken(
                {
                  id: response?.data?.data?.users.id,
                  app_id: DeviceToken,
                  login_device_detail: localStorage.getItem("DeviceData"),
                },
                response?.data?.data?.accessToken
              );
              if (resp?.data?.status) {
                AppContext.setUser({
                  ...resp?.data?.data?.user,
                  token: resp?.data?.data?.accessToken,
                });
                AppContext?.setIsLoggedIn(true);
                AppContext.setLoading(false);
                history.replace("/");
                window.location.assign("/");
              } else {
                AppContext.setLoading(false);
                present({
                  message: response?.data?.errors,
                  duration: 1500,
                  position: "top",
                  color: "primary",
                  cssClass: "alertToast",
                });
              }
            } else {
              history.replace({
                pathname: constant.routes.userlist,
                state: {
                  accessToken: response.data.data.accessToken,
                  id: response.data.data.users.id,
                  mobile: data.mobile,
                  newUser: response.data.data.newUser,
                },
              });
            }
          }
        } else {
          present({
            message: response?.data?.errors,
            duration: 1500,
            position: "top",
            color: "primary",
            cssClass: "alertToast",
          });
        }
      } else if (
        AppContext?.userStatus?.form === constant.SignIn &&
        !AppContext?.userStatus?.isPassword
      ) {
        obj = { mobile: data.mobile };
        let response = await SendOtp(obj);
        if (response.data.status) {
          localStorage.setItem(constant.otpRestore, data.mobile);
          localStorage.setItem(constant.otpSent, "true");
          history.push({
            pathname: constant.routes.otp,
            state: { mobile: data.mobile, otp: response.data?.data?.otp || "" },
          });
        }
      } else {
        obj = { mobile: data.mobile };
        let response = await SendOtp(obj);
        if (response.data.status) {
          localStorage.setItem(constant.otpRestore, data.mobile);
          localStorage.setItem(constant.otpSent, "true");
          history.push({
            pathname: constant.routes.otp,
            state: {
              mobile: data.mobile,
              otp: response?.data?.data?.otp || "",
            },
          });
        }
      }
    } catch (error) {
      const axiosError = error as AxiosError;
      present({
        message: (axiosError?.response?.data as any)?.message,
        duration: 1500,
        position: "top",
        color: "danger",
      });
    } finally {
      AppContext.setLoading(false);
    }
  };

  const forgetPassword = async () => {
    history.push({
      pathname: "/forget-password",
      state: { mobile: watch("mobile") },
    });
  };
  const slidesRef = useRef<null | HTMLIonSlidesElement>(null);

  const slideOpts = {
    initialSlide: 0,
    speed: 400,
    delay: 1000,
    zoom: false,
  };

  const AutoPlay = () => {
    slidesRef.current?.startAutoplay();
  };

  const mediaQuery = window.matchMedia("(min-width: 512px)");

  useEffect(() => {
    let dataString = localStorage.getItem("lastPage");
    let dataStringTwo = localStorage.getItem("responseData");

    let dataObj: any = {};

    if (dataStringTwo) {
      dataObj = JSON.parse(dataStringTwo);
    }
    if (dataString == "/otp" && dataStringTwo) {
      history.push({
        pathname: "/otp",
        state: { mobile: dataObj?.users?.mobile },
      });
    }
  }, []);

  useEffect(() => {
    const handleBackButton = (event: any) => {
      event.preventDefault();
      setShowExitAlert(true);
    };

    CapacitorApp.addListener("backButton", handleBackButton);

    return () => {
      CapacitorApp.removeAllListeners();
    };
  }, []);

  const handleExit = () => {
    CapacitorApp.exitApp();
  };

  return (
    <IonPage>
      <IonAlert
        isOpen={showExitAlert}
        onDidDismiss={() => setShowExitAlert(false)}
        cssClass="actionAlert123"
        header={"Confirm Exit"}
        message={"Are you sure you want to exit the app?"}
        buttons={[
          {
            text: "No",
            role: "cancel",
            cssClass: "alertButton",
          },
          {
            text: "Yes",
            handler: handleExit,
            cssClass: "alertButton",
          },
        ]}
      />
      <div className={mediaQuery.matches ? "test-background-image" : ""}>
        <div className="auth-content">
          <div>
            <img
              loading="lazy"
              className="web-logo"
              src={logo}
              alt="logo-image"
            ></img>
            <div style={{ marginTop: "20px" }} className="web-body-text ">
              <IonLabel>
                <h1 style={{ width: 500 }}>
                  <b>
                    Best Matrimony Site Used By Millions of Jainism Members .
                  </b>
                </h1>
              </IonLabel>
            </div>
          </div>

          <form
            onSubmit={handleSubmit(onSubmit)}
            className="authbottomCover auth-web-bottom"
          >
            <div className="loginForm dasktop-signup">
              <IonTabBar slot="top" className="tab dasktop-signup">
                <IonTabButton
                  tab="Sign-up"
                  onClick={() => {
                    AppContext.setUserStatus({
                      ...AppContext?.userStatus,
                      form: constant.SignUp,
                    });
                  }}
                  className={
                    AppContext?.userStatus?.form === constant.SignUp
                      ? "activename"
                      : ""
                  }
                >
                  <IonLabel>
                    <h2>{t("Auth.signUp")}</h2>
                  </IonLabel>
                </IonTabButton>
                <IonTabButton
                  tab="SignIn"
                  onClick={() => {
                    AppContext.setUserStatus({
                      ...AppContext?.userStatus,
                      form: constant.SignIn,
                    });
                  }}
                  className={
                    AppContext?.userStatus?.form === constant.SignIn
                      ? "activename"
                      : ""
                  }
                >
                  <IonLabel>
                    <h2>{t("Auth.signIn")}</h2>
                  </IonLabel>
                </IonTabButton>
              </IonTabBar>
              <IonList className="p-20">
                <IonItem>
                  <IonLabel position="stacked" className="label">
                    {t("Auth.mobile")}
                  </IonLabel>
                  <div className="languageDropdown">
                    <IonInput
                      value="+ 91"
                      disabled
                      style={{
                        maxWidth: "60px",
                        marginRight: "15px",
                        borderBottom: "0.5px solid #989aa2",
                        lineHeight: "35px",
                      }}
                    ></IonInput>
                    <IonInput
                      type="tel"
                      pattern="[0-9]*"
                      style={{
                        width: "278px",
                        borderBottom: "0.5px solid #989aa2",
                        lineHeight: "35px",
                      }}
                      maxlength={10}
                      {...register("mobile", {
                        required: true,
                        maxLength: 10,
                        minLength: 10,
                      })}
                    ></IonInput>
                  </div>
                </IonItem>
                {errors.mobile && errors.mobile.type === "required" && (
                  <p className="errorText">{t("AuthError.MobileRequire")}</p>
                )}
                {errors.mobile && errors.mobile.type === "maxLength" && (
                  <p className="errorText">{t("AuthError.MobileLength")} </p>
                )}
                {errors.mobile && errors.mobile.type === "minLength" && (
                  <p className="errorText">{t("AuthError.MobileLength")} </p>
                )}

                {AppContext?.userStatus?.isPassword &&
                  AppContext?.userStatus?.form === constant.SignIn && (
                    <>
                      <IonItem>
                        <IonLabel position="stacked" className="label">
                          {t("Auth.password")}
                        </IonLabel>
                        <div className="passBtn">
                          <IonInput
                            type={showPassword ? "text" : "password"}
                            style={{ lineHeight: "35px" }}
                            {...register("password", {
                              required: true,
                              minLength: 6,
                            })}
                          ></IonInput>
                          {!showPassword ? (
                            <IonButton
                              type="button"
                              color={"primary"}
                              fill="clear"
                              shape="round"
                              onClick={() => {
                                setShowPassword(true);
                              }}
                            >
                              <IonIcon
                                icon={eyeSharp}
                                style={{ color: "#797F85" }}
                                className="fs-24"
                              />
                            </IonButton>
                          ) : (
                            <IonButton
                              type="button"
                              fill="clear"
                              color={"primary"}
                              shape="round"
                              onClick={() => {
                                setShowPassword(false);
                              }}
                            >
                              <IonIcon
                                icon={eyeOffSharp}
                                style={{ color: "#797F85" }}
                                className="fs-24"
                              />
                            </IonButton>
                          )}
                        </div>
                      </IonItem>
                      {errors.password &&
                        errors.password.type === "required" && (
                          <p className="errorText">
                            {t("AuthError.PasswordRequire")}
                          </p>
                        )}
                      {errors.password &&
                        errors.password.type === "minLength" && (
                          <p className="errorText">
                            {t("AuthError.PasswordLength")}
                          </p>
                        )}
                      <div
                        className="ion-text-right ion-margin-top"
                        style={{ marginTop: "7px" }}
                      >
                        <IonLabel
                          onClick={forgetPassword}
                          className="ion-text-wrap text-right color-primary f-15 font-weight-600 me-2"
                        >
                          {t("Auth.ForgotPassword")}
                        </IonLabel>
                      </div>
                    </>
                  )}

                <IonGrid>
                  <IonRow>
                    <IonCol class="btnFill mt-1 mb-3">
                      <IonButton type="submit" expand="block">
                        {AppContext?.userStatus?.form === constant.SignUp
                          ? t("Auth.signUp")
                          : t("Auth.signIn")}
                      </IonButton>
                    </IonCol>
                  </IonRow>
                  {AppContext?.userStatus?.form === constant.SignIn && (
                    <>
                      <IonRow>
                        <IonCol>
                          <div className="hr-border-red"></div>
                          <div className="hr-border-yellow"></div>
                          <div className="hr-border-green"></div>
                          <div className="hr-border-blue"></div>
                          <div className="hr-text">OR</div>
                        </IonCol>
                      </IonRow>
                      <IonRow className="ion-text-center">
                        <IonCol class="btnOutline">
                          <IonButton
                            type={"button"}
                            onClick={() => {
                              AppContext.setUserStatus({
                                ...AppContext?.userStatus,
                                isPassword: !AppContext?.userStatus?.isPassword,
                              });
                            }}
                          >
                            {AppContext?.userStatus?.isPassword
                              ? t("Auth.signInWithOtp")
                              : t("Auth.signInWithPassword")}
                          </IonButton>
                        </IonCol>
                      </IonRow>
                    </>
                  )}
                </IonGrid>
              </IonList>
            </div>
          </form>
        </div>
        <IonSlides
          pager={false}
          options={slideOpts}
          ref={slidesRef}
          onIonSlidesDidLoad={AutoPlay}
        >
          <IonSlide>
            <img
              loading="lazy"
              src={
                !mediaQuery.matches
                  ? AppContext?.userStatus?.isPassword &&
                    AppContext?.userStatus?.form === constant.SignIn
                    ? Img2
                    : // ? Img1
                      Img2
                  : ""
              }
              style={{ objectFit: "cover", width: "100%" }}
            ></img>
          </IonSlide>
          <IonSlide>
            <img
              loading="lazy"
              src={
                !mediaQuery.matches
                  ? AppContext?.userStatus?.isPassword &&
                    AppContext?.userStatus?.form === constant.SignIn
                    ? Img3
                    : // ? Img4
                      Img3
                  : ""
              }
              style={{ objectFit: "cover", width: "100%" }}
            ></img>
          </IonSlide>
          <IonSlide>
            <img
              loading="lazy"
              src={
                !mediaQuery.matches
                  ? AppContext?.userStatus?.isPassword &&
                    AppContext?.userStatus?.form === constant.SignIn
                    ? Img5
                    : // ? Img6
                      Img5
                  : ""
              }
              style={{ objectFit: "cover", width: "100%" }}
            ></img>
          </IonSlide>
        </IonSlides>
      </div>
    </IonPage>
  );
};

export default Auth;
